import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Permission, Role } from '../../constants';

export enum Route {
  IDLE,
  ADD_PERMISSIONS,
  EDIT_PERMISSION,
  ADD_ROLES,
  EDIT_ROLE,
}
export interface AuthorizationState {
  showSidePanel: boolean;
  canSaveChanges: boolean;
  route: Route;
  selectedPermission?: Permission;
  selectedRole?: Role;
}

const initialState: AuthorizationState = {
  showSidePanel: false,
  route: Route.IDLE,
  selectedPermission: undefined,
  selectedRole: undefined,
  canSaveChanges: false,
};

export const {
  reducer: authorizationReducer,
  actions: {
    updateShowSidePanel,
    updateAuthorizationRoute,
    setSelectedPermission,
    setSelectedRole,
    updateCanSaveChanges,
  },
} = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    updateShowSidePanel: (state, { payload }: PayloadAction<boolean>) => {
      state.showSidePanel = payload;
    },
    updateCanSaveChanges: (state, { payload }: PayloadAction<boolean>) => {
      state.canSaveChanges = payload;
    },
    updateAuthorizationRoute: (state, { payload }: PayloadAction<Route>) => {
      state.route = payload;
    },
    setSelectedPermission: (state, { payload }: PayloadAction<Permission | undefined>) => {
      state.selectedPermission = payload;
    },
    setSelectedRole: (state, { payload }: PayloadAction<Role | undefined>) => {
      state.selectedRole = payload;
    },
  },
});

export default authorizationReducer;
