import { type RootState, useAppSelector } from '..';
import type { User } from '../../services/usersService';
import type { SearchedUser } from '../../services/usersService/usersServiceQuery';

export const getManageUsers = (state: RootState) => state.manageUsers;

export const useManageUsers = () => useAppSelector(getManageUsers);

export const getRouteParams = <T>() => useAppSelector(getManageUsers).editUserRoute.routeParams as T;

export const isRepAdminSelector = (state: RootState) =>
  state.adminShell.user.permissions.includes('carrierpro.RepCompanyAdmin');

export const isCompanyAdminSelector = (state: RootState) =>
  state.adminShell.user.permissions.includes('carrierpro.CompanyAdmin');

export const isCurrentUserSelector = (user: User | SearchedUser | undefined) => (state: RootState) => {
  if (!user) {
    return false;
  }
  return state.adminShell.user.email === user.email;
};

export const getCurrentUserSelector = (state: RootState) => {
  return state.adminShell.user;
};
export const companySelector = (state: RootState) => {
  return state.adminShell.userCompanyData;
};
