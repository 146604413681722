import React, { type ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { JOYRIDES } from './joyrides';

export interface JoyrideAnalytics {
  onClose?: () => void;
  onSkip?: () => void;
  onNext?: () => void;
  onBack?: () => void;
}

export interface JoyrideState {
  run: boolean;
  joyride?: JOYRIDES;
  analytics?: JoyrideAnalytics;
}

const defaultJoyrideState: JoyrideState = {
  run: false,
};

export const JoyrideContext = createContext({
  state: defaultJoyrideState,
  setState: (_: Partial<JoyrideState>) => {
    return;
  },
});
JoyrideContext.displayName = 'JoyrideContext';

export function JoyrideProvider({ children }: { children: ReactNode }) {
  const [internalState, setInternalState] = useState(defaultJoyrideState);

  const value = useMemo(
    () => ({
      state: internalState,
      setState: (payload: Partial<JoyrideState>) => {
        setInternalState((prevInternalState) => ({ ...prevInternalState, ...payload }));
      },
    }),
    [setInternalState, internalState],
  );

  return (
    <JoyrideContext.Provider value={value}>
      <>{children}</>
    </JoyrideContext.Provider>
  );
}

export function useJoyrideContext(): {
  setState: (patch: Partial<JoyrideState>) => void;
  state: JoyrideState;
} {
  const context = useContext(JoyrideContext);

  if (!context) {
    throw new Error('useJoyrideContext must be used within a JoyrideProvider');
  }

  return context;
}
