import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';

interface ClientAccountGetResponse {
  clientKey: number;
  clientNumber: string;
  email: string;
  mc_number: string;
  clientName: string;
}

export const stcFactoringClientServiceApi = createApi({
  reducerPath: 'stcFactoringClientServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.stcFactoringClientServiceUrl }),
  endpoints: (builder) => ({
    getFactoringClient: builder.query<ClientAccountGetResponse, string>({
      query: (clientNumber) => ({
        method: 'GET',
        url: `clients/${clientNumber}`,
      }),
    }),
  }),
});

export const { useLazyGetFactoringClientQuery } = stcFactoringClientServiceApi;
