import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, { CustomDetector } from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import es from './translations/es.json';

const resources = {
  en,
  es,
};

export type SupportedLanguages = keyof typeof resources;

const I18N_LANGUAGE = 'i18nextLng';

export const getLanguageFromLocalStorage = () => {
  return localStorage.getItem(I18N_LANGUAGE);
};

const setLanguageLocalStorage = (language: SupportedLanguages) => {
  return localStorage.setItem(I18N_LANGUAGE, language);
};

const detector: CustomDetector = {
  name: 'LanguageDetector',
  lookup: (_options) => {
    const language = getLanguageFromLocalStorage();

    // if no language has ever been set, detect it from browser
    if (language === null) {
      // languages in chrome can return language and location
      // ex: en-US, es-AR, en, es
      const [browserLanguage] = navigator.language.split('-');

      if (resources[browserLanguage as SupportedLanguages]) {
        return browserLanguage;
      }

      return 'en';
    }

    return language;
  },
  cacheUserLanguage: (language: SupportedLanguages, _options) => {
    setLanguageLocalStorage(language);
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(detector);
languageDetector.init(null, { order: ['LanguageDetector'] });

i18n.use(languageDetector).use(initReactI18next).init({
  resources,
  defaultNS: 'common',
  fallbackLng: 'en',
});

export default i18n;
