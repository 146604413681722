import React, { FC } from 'react';
import { Rep } from '../../../services/repRetrievalService';
import { RepCard } from './RepCard';
import { NoAccessCard } from './NoAccessCard';
import { AccountRepSelection } from './AccountRepSelection';

interface RepCardFilterProps {
  icon: string;
  serviceName: string;
  target: string;
  data?: Rep;
}

export const RepCardFilter: FC<RepCardFilterProps> = ({ icon, serviceName, target, data }) => {
  if (!data?.name) {
    return <NoAccessCard icon={icon} serviceName={serviceName} target={target} />;
  }
  if (!data.accounts) {
    return <RepCard icon={icon} serviceName={serviceName} data={data} />;
  } else {
    return <AccountRepSelection icon={icon} serviceName={serviceName} data={data} />;
  }
};
