import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.scss';
import { Icon } from 'shamrock-clover-ui';
import { RepInfoBox } from '../RepInfoBox';
import { capitalizeString, formatPhoneNumber } from '../../../utils';
import { SearchedUser, UserStatus } from '../../../services/usersService/usersServiceQuery';

type Props = {
  user: SearchedUser;
  defaultOpen: boolean;
};
const CarrierDirectoryItem = ({ user, defaultOpen }: Props) => {
  const { t, i18n } = useTranslation();
  const [openDirectoryRep, setOpenDirectoryRep] = useState(defaultOpen);

  const userLanguages = useMemo(() => {
    const copyArray = user.device_info
      ? [...user.device_info.devices_by_language]
      : [{ rtspro_language: 'english', device_count: '1' }];
    return copyArray
      .sort((a, b) => {
        if (a.device_count > b.device_count) {
          return -1;
        } else if (a.device_count < b.device_count) {
          return 1;
        }
        return 0;
      })
      .map((deviceObj) => deviceObj.rtspro_language)
      .slice(0, 3)
      .join(', ');
  }, [user.device_info]);

  const statusLabel = useMemo(() => {
    switch (user.status) {
      case UserStatus.Active:
        return t('active');
      case UserStatus.Inactive:
        return t('inactive');
      // TODO: Add translations for remaining statuses
      default:
        return user.status;
    }
  }, [i18n.language, user.status]);

  return (
    <div className={styles['container-item']}>
      <div className={styles['table-row']}>
        <div className={`${styles['table-cell']} ${user.name ? styles['name'] : styles['trialName']}`}>
          <p className={styles['cell-text']}>{user.name ?? 'Trial User'}</p>
        </div>
        <div className={styles['table-cell']}>
          <p className={styles['cell-text']}>{user.company?.toLocaleUpperCase()}</p>
        </div>
        <div className={styles['table-cell']}>{user.company_id}</div>
        <div className={styles['table-cell']}>
          <p className={styles['cell-text']}>{user.email}</p>
        </div>
        <div className={styles['table-cell']}>{formatPhoneNumber(user.phone)}</div>
        <div className={styles['table-cell']}>{capitalizeString(statusLabel)}</div>
      </div>
      <div className={styles['rep-button']} onClick={() => setOpenDirectoryRep(!openDirectoryRep)}>
        <div className={styles.dropdownContainer}>
          <span style={{ marginRight: '5px' }}>View Rep Directory</span>
          <Icon size="12" icon="dropdownDown" />
        </div>
        <div className={styles.languageContainer}>
          <p className={styles.boldText}>Language Preference:</p>
          <p className={styles.regularText}>{userLanguages}</p>
        </div>
      </div>
      {openDirectoryRep && <RepInfoBox email={user.email} />}
    </div>
  );
};

export default CarrierDirectoryItem;
