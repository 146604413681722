import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// TODO FINANCIAL TEAM MAY NOT NEED THIS EMAIL OVERRIDE

type AdvancementInvitationState = {
  toEmailOverride: string;
};

const initialState: AdvancementInvitationState = {
  toEmailOverride: '',
};

export const {
  actions: { setToEmailOverride },
  reducer: advancementInvitationsReducer,
} = createSlice({
  name: 'advancementInvitations',
  initialState,
  reducers: {
    setToEmailOverride(state, { payload }: PayloadAction<string>) {
      state.toEmailOverride = payload;
    },
  },
});
