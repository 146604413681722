import React, { FC } from 'react';
import { ServiceHeader } from './ServiceHeader';
import { LearnMoreBtn } from '../LearnMoreBtn';
import styles from './repStyles.scss';

interface NoAccessCardProps {
  icon: string;
  serviceName: string;
  target: string;
}

export const NoAccessCard: FC<NoAccessCardProps> = ({ icon, serviceName, target }) => {
  return (
    <div className={styles.repCard}>
      <ServiceHeader icon={icon} serviceName={serviceName} faded />
      <p className={styles.noFeatureText}>
        You don&apos;t have access
        <br />
        to this feature yet.
      </p>
      <LearnMoreBtn target={target} />
    </div>
  );
};
