// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrimaryNavigation__toolbarActions___WjGlb {
  display: flex;
  margin: 0 0 0 auto;
  cursor: pointer;
  z-index: 1000;
}

.PrimaryNavigation__appBar___J2iuM {
  position: static;
  height: 64px;
  background-color: #ffffff;
  padding: 0 48px;
  border-bottom: 1px solid #F3F6F7;
  display: flex;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2), inset 0px -1px 0px #EAF7FF;
}
.PrimaryNavigation__appBar___J2iuM nav {
  font-family: proxima-nova, sans-serif;
}
.PrimaryNavigation__appBar___J2iuM .PrimaryNavigation__toolBar____5BIS {
  display: flex;
  height: 100%;
  align-items: center;
}
.PrimaryNavigation__appBar___J2iuM .PrimaryNavigation__logo___cMrkG {
  width: 135px;
  height: 28px;
}
.PrimaryNavigation__appBar___J2iuM .PrimaryNavigation__navItem___GzzGE {
  display: flex;
  height: 100%;
  align-items: center;
  color: #0091ea;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border-bottom: 2.5px solid transparent;
  font-family: proxima-nova, Roboto, sans-serif;
  text-decoration: none;
}
.PrimaryNavigation__appBar___J2iuM .PrimaryNavigation__navItem___GzzGE.PrimaryNavigation__active___rs_LC, .PrimaryNavigation__appBar___J2iuM .PrimaryNavigation__navItem___GzzGE:hover {
  border-bottom: 2.5px solid #0091ea;
  border-bottom-width: calc(100% + 32px);
}`, "",{"version":3,"sources":["webpack://./src/AdminShell/styles/PrimaryNavigation.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;AAAF;;AAGA;EACE,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,gCAAA;EAEA,aAAA;EACA,sEAAA;AADF;AAGE;EACE,qCAAA;AADJ;AAIE;EACE,aAAA;EACA,YAAA;EACA,mBAAA;AAFJ;AAKE;EACE,YAAA;EACA,YAAA;AAHJ;AAME;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,sCAAA;EACA,6CAAA;EACA,qBAAA;AAJJ;AAMI;EACE,kCAAA;EACA,sCAAA;AAJN","sourcesContent":["\n.toolbarActions {\n  display: flex;\n  margin: 0 0 0 auto;\n  cursor: pointer;\n  z-index: 1000;\n}\n\n.appBar {\n  position: static;\n  height: 64px;\n  background-color: #ffffff;\n  padding: 0 48px;\n  border-bottom: 1px solid #F3F6F7;\n  //box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);\n  display: flex;\n  box-shadow:0px 2px 2px rgba(0, 0, 0, 0.2), inset 0px -1px 0px #EAF7FF;\n\n  nav {\n    font-family: proxima-nova, sans-serif;\n  }\n\n  .toolBar {\n    display: flex;\n    height: 100%;\n    align-items: center;\n  }\n\n  .logo {\n    width: 135px;\n    height: 28px;\n  }\n\n  .navItem {\n    display: flex;\n    height: 100%;\n    align-items: center;\n    color: #0091ea;\n    padding: 0 16px;\n    font-size: 16px;\n    font-weight: 400;\n    cursor: pointer;\n    border-bottom: 2.5px solid transparent;\n    font-family: proxima-nova, Roboto, sans-serif;\n    text-decoration: none;\n\n    &.active, &:hover {\n      border-bottom: 2.5px solid #0091ea;\n      border-bottom-width: calc(100% + 32px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarActions": `PrimaryNavigation__toolbarActions___WjGlb`,
	"appBar": `PrimaryNavigation__appBar___J2iuM`,
	"toolBar": `PrimaryNavigation__toolBar____5BIS`,
	"logo": `PrimaryNavigation__logo___cMrkG`,
	"navItem": `PrimaryNavigation__navItem___GzzGE`,
	"active": `PrimaryNavigation__active___rs_LC`
};
export default ___CSS_LOADER_EXPORT___;
