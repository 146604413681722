import React, { FC, useMemo, useState } from 'react';
import css from './RepInfoBox.scss';
import { Icon, SelectInput, Spinner, Tooltip } from 'shamrock-clover-ui';
import emailIcon from '../../assets/images/email-icon.svg';
import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import { Rep, useGetFactoringRepQuery, useGetRepsQuery } from '../../services/repRetrievalService';
import { formatPhoneNumber } from '../../utils';

interface RepInfoBoxProps {
  email: string;
}
interface RepCardProps {
  rep: Rep;
  email: string;
}

const toolTipTitle: Record<string, string> = {
  'RTS Financial': 'Factoring',
  'Ryan Transportation': 'Freight',
  'Carrier Services': 'Fuel',
  ProTransport: 'TM Software',
};

export const RepInfoBox: FC<RepInfoBoxProps> = ({ email }) => {
  const { data, isLoading } = useGetRepsQuery(email);
  return (
    <div className={css.repInfoBoxContainer}>
      {isLoading ? (
        <div className={css.loadingSpinnerWide}>
          <Spinner size="32" />
          <span>Loading...</span>
        </div>
      ) : (
        data?.map((rep, idx) => {
          return (
            <React.Fragment key={`${rep?.product}-${idx}`}>
              <RepCard rep={rep} email={email} />
              <div className={css.lineBorder} />
            </React.Fragment>
          );
        })
      )}
    </div>
  );
};

const RepCard: FC<RepCardProps> = ({ rep: initRep, email }) => {
  const [valueSelected, setValueSelected] = useState(initRep.activeAccount);
  const [isLoading, setIsLoading] = useState(false);
  const mappedAccounts = useMemo(
    () =>
      initRep.accounts?.map((value: string): MenuOption => {
        const valueActive = value === initRep.activeAccount ? value + ' (Active)' : value;
        return { optionName: valueActive, value };
      }),
    [initRep.accounts],
  );

  const selected = useMemo(
    () => mappedAccounts?.find((account) => account.value === valueSelected),
    [mappedAccounts, valueSelected],
  );

  const skip = !selected || !initRep.accounts;
  const { data: [resData] = [] } = useGetFactoringRepQuery(
    { account: selected?.value as string, email, setIsLoading },
    { skip },
  );

  const rep = resData || initRep;

  return (
    <div className={css.repCardContainer}>
      <div className={css.productTitleContainer}>
        <p className={css.productTitle}>{rep.product}</p>
        <Tooltip title={toolTipTitle[rep.product]} placement="top">
          <Icon icon="attention" size="16" />
        </Tooltip>
      </div>
      {rep.accounts && (
        <SelectInput
          label="Account Number"
          value={selected}
          onOptionSelected={(option?: MenuOption) => option?.value && setValueSelected(option.value as string)}
          options={mappedAccounts ?? []}
          variant="filled"
        />
      )}
      <CardBody isLoading={isLoading} rep={rep} />
    </div>
  );
};

const CardBody: FC<{ isLoading: boolean; rep: Rep }> = ({ rep, isLoading }) =>
  isLoading ? <LoadingFactoring /> : <RepData rep={rep} />;

const LoadingFactoring: FC = () => {
  return (
    <div className={css.loadingSpinner}>
      <Spinner size="35" />
      <span>Loading...</span>
    </div>
  );
};

const RepData: FC<{ rep: Rep }> = ({ rep }) => {
  if (rep.name) {
    const phone = formatPhoneNumber(rep.phone);
    return (
      <div>
        <div className={css.nameContainer}>
          <p className={css.repTitle}>{`Rep:`}</p>
          <p className={css.repName}>{rep.name}</p>
        </div>
        <div className={css.repSubItemContainer}>
          <div className={css.phoneContainer}>
            <Icon icon="phone" color="gray" size="20" />
            <p className={css.phoneNumber}>{phone}</p>
          </div>
          <p className={css.extContainer}>{`Ext: ${rep.ext ?? 'N/A'}`}</p>
          <div className={css.emailContainer}>
            <img alt="email-icon" src={emailIcon} />
            <p className={css.email}>{rep.email}</p>
          </div>
        </div>
        <div className={css.officeInfo}>
          <p className={css.officeInfoText}>{`Office: ${rep.office ? rep.office : 'N/A'}`}</p>
          <p className={css.officeInfoText}>{`Timezone: ${rep.timezone ? rep.timezone : 'N/A'}`}</p>
        </div>
      </div>
    );
  } else {
    return (
      <p className={css.noProduct}>
        Carrier does not <br /> have this service
      </p>
    );
  }
};
