import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import {
  AdvancementInvitationHistory,
  AdvancementInvitation,
  CreateInvitationPayload,
  UpdateInvitationByIdDirectInvokeRequest,
  CreateInvitationHistoryDirectInvokeRequest,
  CreatePdfViewHistoryDirectInvokeRequest,
} from 'rtspro-financial-core-module';

type GenerateFinicityPdfRequest = {
  email: string;
  invitationId: string;
};

export const advancementInvitationServiceApi = createApi({
  reducerPath: 'advancementInvitationServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.advancementInvitationServiceUrl }),
  tagTypes: ['Invitations', 'InvitationHistories'],
  endpoints: (builder) => ({
    createInvitation: builder.mutation<any, CreateInvitationPayload>({
      query: (invitationData) => {
        return {
          url: `/invitations`,
          method: 'POST',
          data: invitationData,
        };
      },
      invalidatesTags: [
        { type: 'Invitations', id: 'LIST' },
        { type: 'InvitationHistories', id: 'LIST' },
      ],
    }),

    updateInvitation: builder.mutation<AdvancementInvitation, UpdateInvitationByIdDirectInvokeRequest>({
      query: (invitationData) => {
        return {
          url: `/invitations/${invitationData.id}`,
          method: 'PATCH',
          data: {
            status: invitationData.payload.status,
          },
        };
      },
      invalidatesTags: [
        { type: 'Invitations', id: 'LIST' },
        { type: 'InvitationHistories', id: 'LIST' },
      ],
    }),

    getInvitations: builder.query<AdvancementInvitation[], string>({
      query: (email) => ({
        url: `/invitations?email=${email}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Invitations', id: 'LIST' },
              ...result.map(({ _id }) => ({ type: 'Invitations' as const, id: _id })),
            ]
          : [{ type: 'Invitations', id: 'LIST' }],
    }),

    getInvitationHistories: builder.query<AdvancementInvitationHistory[], string>({
      query: (email) => ({
        url: `/invitations/histories/${email}`,
        method: 'GET',
      }),
      transformResponse: (response: AdvancementInvitationHistory[]) => {
        const sortedInvitationsHistoriesResponse = [...response].sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        return sortedInvitationsHistoriesResponse;
      },
      providesTags: (result) =>
        result
          ? [
              { type: 'InvitationHistories', id: 'LIST' },
              ...result.map(({ _id }) => ({ type: 'InvitationHistories' as const, id: _id })),
            ]
          : [{ type: 'InvitationHistories', id: 'LIST' }],
    }),

    createInvitationHistory: builder.mutation<
      AdvancementInvitationHistory,
      Omit<CreateInvitationHistoryDirectInvokeRequest, 'requestContext'>
    >({
      query: (invitationData) => {
        return {
          url: `/invitations/histories/${invitationData.email}`,
          method: 'POST',
          data: invitationData.payload,
        };
      },
      invalidatesTags: [{ type: 'InvitationHistories', id: 'LIST' }],
    }),

    createPdfViewHistory: builder.mutation<any, Omit<CreatePdfViewHistoryDirectInvokeRequest, 'requestContext'>>({
      query: (invitationData) => {
        return {
          url: `/invitations/${invitationData.id}/pdfs/${invitationData.pdfId}/history`,
          method: 'POST',
        };
      },
      invalidatesTags: [{ type: 'Invitations', id: 'LIST' }],
    }),

    generatePdf: builder.mutation<any, GenerateFinicityPdfRequest>({
      query: (data) => {
        return {
          url: `${config.bankAccountServiceUrl}/finicity/${data.email}/generatePdf/${data.invitationId}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useCreatePdfViewHistoryMutation,
  useCreateInvitationMutation,
  useCreateInvitationHistoryMutation,
  useUpdateInvitationMutation,
  useGetInvitationHistoriesQuery,
  useGetInvitationsQuery,
  useLazyGetInvitationHistoriesQuery,
  useLazyGetInvitationsQuery,
  useGeneratePdfMutation,
} = advancementInvitationServiceApi;
