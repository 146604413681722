import { redirectToLogin } from '../utils';
import { AxiosError, AxiosInstance } from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import Store from '../store';
import { ClientError } from 'graphql-request';
import { auth0LoginRedirect, isFeatureEnabled } from '@Common/utils';

export function addInterceptors(axios: AxiosInstance) {
  axios.interceptors.request.use(
    async (config) => {
      const adminShellState = Store.getState().adminShell;
      const getAccessToken = adminShellState?.getAccessToken;
      const oldToken = LocalAuth.getTokenValue();
      let token = oldToken;
      const isAuth0Enabled = isFeatureEnabled('43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd');
      if (!token && getAccessToken && isAuth0Enabled) token = await getAccessToken();
      if (token && config.headers) {
        (config.headers as { Authorization: string }).Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
  );
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      await handleErrors(error);
    },
  );
}

export async function handleErrors(error: ClientError) {
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    const isAuth0Enabled = isFeatureEnabled('43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd');
    if (isAuth0Enabled) {
      LocalAuth.clearStorage();
      await auth0LoginRedirect();
    } else {
      redirectToLogin(window.location.href);
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
}
