import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import { Company, CompanyResponse } from '.';

export const companyServiceRTKQuery = createApi({
  reducerPath: 'companyServiceRTKQuery',
  baseQuery: axiosBaseQuery({
    baseUrl: config.companyServiceUrl,
  }),
  tagTypes: ['Company', 'CompanyByEmail'],
  endpoints: (builder) => ({
    patchCompany: builder.mutation<unknown, Partial<Company>>({
      query: (data) => {
        const { _id, ...requestData } = data;
        return {
          url: `/${data.company_id}`,
          method: 'PATCH',
          data: requestData,
        };
      },
      invalidatesTags: ['Company'],
    }),
    getCompany: builder.query<Company | undefined, string>({
      query: (company_id) => ({
        url: ``,
        method: 'GET',
        params: { company_id },
      }),
      providesTags: ['Company'],
      transformResponse: (companies: Company[], _, arg) => {
        const company = companies.find(({ company_id }) => company_id === arg);
        return company;
      },
    }),
    getCompanyByEmail: builder.query<Company | undefined, string>({
      query: (email) => ({
        url: `/${email}`,
        method: 'GET',
      }),
      providesTags: ['Company'],
      transformResponse: (companies: Company[]) => {
        const company = companies[0];
        return company;
      },
    }),
    getCompaniesByName: builder.query<CompanyResponse[], string>({
      query: (company_name) => ({
        url: ``,
        method: 'GET',
        params: { company_name },
      }),
      providesTags: ['Company'],
    }),
  }),
});

export const {
  usePatchCompanyMutation,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useGetCompaniesByNameQuery,
  useLazyGetCompaniesByNameQuery,
  useGetCompanyByEmailQuery,
} = companyServiceRTKQuery;
