import React, { FC, ReactNode, useState } from 'react';
import styles from '../styles.scss';
import { Icon } from 'shamrock-clover-ui';

interface DropdownContainerProps {
  content: ReactNode;
  children?: ReactNode;
  initialOpen?: boolean;
}

export const DropdownContainer: FC<DropdownContainerProps> = ({ content, children, initialOpen }) => {
  const [isVisible, setIsVisible] = useState(initialOpen);

  const handleOnClick = () => {
    setIsVisible((prevIsVisible) => !prevIsVisible);
  };

  return (
    <div>
      <button className={styles.dropDownContainer} onClick={handleOnClick}>
        <div className={styles.contentText}>{content}</div>
        <div className={styles.contentIcon}>
          <div>
            <Icon
              icon="chevronDown"
              size="24"
              color="white"
              className={isVisible ? styles.rotateIconOpen : styles.rotateIconClosed}
            />
          </div>
        </div>
      </button>
      {isVisible && children}
    </div>
  );
};
