import React, { FC } from 'react';
import cx from 'classnames';
import styles from './repStyles.scss';

interface ServiceHeaderProps {
  serviceName: string;
  icon: string;
  faded?: boolean;
}
export const ServiceHeader: FC<ServiceHeaderProps> = ({ serviceName, icon, faded }) => {
  return (
    <div className={cx(styles.serviceHeader, faded && styles.serviceheaderFaded)}>
      <img src={icon} className={styles.serviceTitle} />
      <div className={cx(styles.serviceTitle, faded && styles.serviceheaderFaded)}>{serviceName}</div>
    </div>
  );
};
