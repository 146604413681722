import { DASHBOARD_ROUTES } from './CMS/common/constants';
import { HomescreenWidget, MarketingWidget } from './store/widget';
import { TOOLS_ROUTES } from './Tools/constants';

export const CMS_ROUTE = 'dashboard-cards';
export const EXT_REGISTER_ROUTE = 'register';
export const TOOLS_ROUTE = 'rtspro-tools';

export const MANAGE_USERS_ROUTE = 'manage-users';
export const AUTHORIZATION_ROUTE = 'authorization';

export enum TabsTitle {
  RtsPro = 'RTS Pro',
  CreditTrial = 'CreditTrial',
  ManageUsersDashboard = 'Dashboard',
  Roles = 'Roles',
  Permissions = 'Permissions',
}

export enum ToolTabsTitles {
  MassInvite = 'Mass Invite Users',
  MergeCompanies = 'Merge Companies',
  DeviceLimitsWhiteList = 'Device Limits Whitelist',
  MFAWhiteList = 'MFA WhiteList',
  PasswordUpdates = 'Password Updates',
}
export enum AppRoutes {
  ADMIN_SHELL_HOME = '',
  CMS = 'dashboard-cards',
  MANAGE_USERS = 'manage-users',
  AUTHORIZATION = 'authorization',
  SETTINGS = 'settings',
  RTSPRO_TOOLS = 'rtspro-tools',
  CARRIER_DIRECTORY = 'carrier-directory',
  CONTACT_US = 'contact-us',
}

export const GLOBAL_CSS_CONTAINER = 'container';

export const products = [
  'Credit',
  'Factoring',
  'Freight',
  'Fuel',
  'Load Management',
  'OOIADA',
  'Promotional',
  'Shamrock',
];

export enum MarketingTypes {
  HOMESCREEN_WIDGET = 'HOMESCREEN_WIDGET',
  MARKETING_WIDGET = 'MARKETING_WIDGET',
  TRIAL_HOMESCREEN_WIDGET = 'TRIAL_HOMESCREEN_WIDGET',
  TRIAL_MARKETING_WIDGET = 'TRIAL_MARKETING_WIDGET',
  ORDER_DOC = 'ORDER_DOC',
}

export const routeBuilder =
  <T extends string>(route: string) =>
  (strings: TemplateStringsArray, ...routes: Array<DASHBOARD_ROUTES | TOOLS_ROUTES | T>) => {
    const filteredStrings = strings.filter((str) => str !== '');
    if (filteredStrings.length > 2 && filteredStrings.some((str) => str !== '/')) console.error('Malformed CMS path');
    const parsedRoutes = routes.reduce((acc, el, index) => acc + (filteredStrings[index] ?? '') + el, '');
    return `${route}/${parsedRoutes}`;
  };

export const MARKETING_IMAGE_DIMENSION = 234;
export const HOMESCREEN_IMAGE_DIMENSION = 120;

export type MarketingWidgets = Exclude<MarketingTypes, 'ORDER_DOC'>;

export type HomescreenWidgetTypes = Exclude<MarketingWidgets, 'MARKETING_WIDGET' | 'TRIAL_MARKETING_WIDGET'>;

export type MarketingWidgetTypes = Exclude<MarketingWidgets, 'HOMESCREEN_WIDGET' | 'TRIAL_HOMESCREEN_WIDGET'>;

export type WidgetReturnType<Type extends MarketingWidgets> = Type extends HomescreenWidgetTypes
  ? HomescreenWidget
  : MarketingWidget;

export const MAX_LINK_TEXT_LENGTH = 30;
export const MAX_BODY_LENGTH = 130;
export const MAX_HEADER_LENGTH = 27;
export const MAX_HOMESCREEN_BODY_LENGTH = 78;

export const HOME_SCREEN_SCHEMA = 'HOME_SCREEN_SCHEMA';

//TODOIF: Type bigger than two, convert to ENUM
export type SchemaKey = typeof HOME_SCREEN_SCHEMA;

export type SessionAuth = {
  token: string;
  email: string;
};

export interface Role {
  _id: string;
  name: string;
  description: string;
  permissions: Array<string>;
  group?: string;
  salesforce: {
    category: string;
  };
  permanent: boolean;
  default_invite_role: boolean;
  excluded?: Array<string>;
}
export type RoleInput = Partial<Pick<Role, 'name' | 'description' | 'group'>>;

export interface Permission {
  _id?: string;
  name: string;
  category: string;
  type: string;
  display_name: string;
  description: string;
  group: string;
}
