import React from 'react';
import 'shamrock-clover-ui/dist/clover/css/clover.css';
import './index.scss';
import App from './App';
import './i18n';
import { CloverThemeProvider } from 'shamrock-clover-ui';
import { Provider } from 'react-redux';
import store from './store';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';

const container = document.getElementById('app');
const root = createRoot(container as HTMLElement);
export default function bootstrap() {
  root.render(
    <Auth0Provider
      domain={config.auth0CustomDomain}
      clientId={config.auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `https://${config.auth0Domain}/api/v2/`,
        scope: 'openid profile email offline_access',
      }}
    >
      <Provider store={store}>
        <CloverThemeProvider>
          <App />
        </CloverThemeProvider>
      </Provider>
    </Auth0Provider>,
  );
}
