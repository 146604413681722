import { ThunkMiddleware, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import filterReducer from './filters';
import editTrayReducer from './editTray';
import widgetReducer from './widget';
import manageUsersReducer from './manageUsers';
import { advancementInvitationsReducer } from './advancementInvitations';
import { adminShellReducer } from './adminShell';
import { repRetrievalApi } from '../services/repRetrievalService';
import { userServiceRTKApi } from '../services/usersService/usersServiceQuery';
import { financialUsersServiceRTKApi } from '../services/usersService/financialUsersServiceQuery';
import { rolesServiceApi } from '../services/rolesService';
import { emailServiceApi } from '../services/emailService';
import { messagingServiceApi } from '../services/messagingService';
import { invoiceManagerUsersServiceApi } from '../services/InvoiceManagerUsersService';
import { financialServiceApi } from '../services/financialService';
import { invitationServiceApi } from '../services/invitationService/invitationQuery';
import { companyCompositionQuery } from '../services/usersService/companyCompositionQuery';
import { tokenServiceApi } from '../services/tokenService/tokenServiceRTKQuery';
import { dataHistoryServiceApi } from '../services/dataHistoryService/dataHistoryServiceRTKQuery';
import { fuelServiceApi } from '../services/fuelService';
import { permissionsServiceApi } from '../services/permissionsService/permissionServiceRTKQuery';
import { advancementInvitationServiceApi } from '../services/advancementInvitationService';
import { companyServiceRTKQuery } from '../services/companyService/CompanyServiceRTKQuery';
import authorizationReducer from './authorization';
import { stcFactoringClientServiceApi } from '../services/stcFactoringClientService';
import { internalClientServiceApi } from '../services/internalClientService';

const store = configureStore({
  reducer: {
    filter: filterReducer,
    editTray: editTrayReducer,
    widgets: widgetReducer,
    adminShell: adminShellReducer,
    authorization: authorizationReducer,
    manageUsers: manageUsersReducer,
    advancementInvitations: advancementInvitationsReducer,
    [repRetrievalApi.reducerPath]: repRetrievalApi.reducer,
    [userServiceRTKApi.reducerPath]: userServiceRTKApi.reducer,
    [financialUsersServiceRTKApi.reducerPath]: financialUsersServiceRTKApi.reducer,
    [rolesServiceApi.reducerPath]: rolesServiceApi.reducer,
    [emailServiceApi.reducerPath]: emailServiceApi.reducer,
    [messagingServiceApi.reducerPath]: messagingServiceApi.reducer,
    [invoiceManagerUsersServiceApi.reducerPath]: invoiceManagerUsersServiceApi.reducer,
    [financialServiceApi.reducerPath]: financialServiceApi.reducer,
    [stcFactoringClientServiceApi.reducerPath]: stcFactoringClientServiceApi.reducer,
    [invitationServiceApi.reducerPath]: invitationServiceApi.reducer,
    [companyCompositionQuery.reducerPath]: companyCompositionQuery.reducer,
    [tokenServiceApi.reducerPath]: tokenServiceApi.reducer,
    [fuelServiceApi.reducerPath]: fuelServiceApi.reducer,
    [permissionsServiceApi.reducerPath]: permissionsServiceApi.reducer,
    [dataHistoryServiceApi.reducerPath]: dataHistoryServiceApi.reducer,
    [advancementInvitationServiceApi.reducerPath]: advancementInvitationServiceApi.reducer,
    [companyServiceRTKQuery.reducerPath]: companyServiceRTKQuery.reducer,
    [internalClientServiceApi.reducerPath]: internalClientServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });
    middleware.push(
      repRetrievalApi.middleware as ThunkMiddleware,
      userServiceRTKApi.middleware as ThunkMiddleware,
      financialUsersServiceRTKApi.middleware as ThunkMiddleware,
      rolesServiceApi.middleware as ThunkMiddleware,
      emailServiceApi.middleware as ThunkMiddleware,
      messagingServiceApi.middleware as ThunkMiddleware,
      invoiceManagerUsersServiceApi.middleware,
      financialServiceApi.middleware,
      stcFactoringClientServiceApi.middleware,
      invitationServiceApi.middleware,
      companyCompositionQuery.middleware,
      tokenServiceApi.middleware,
      fuelServiceApi.middleware,
      permissionsServiceApi.middleware,
      dataHistoryServiceApi.middleware,
      advancementInvitationServiceApi.middleware,
      companyServiceRTKQuery.middleware,
      internalClientServiceApi.middleware,
    );
    return middleware;
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
