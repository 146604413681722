import React, { FC } from 'react';
import { Button } from 'shamrock-clover-ui';
import { useTranslation } from 'react-i18next';
import styles from './AlertButtons.scss';

type Props = {
  onDiscard: () => void;
  onContinue: () => void;
  discardText?: string;
  continueText?: string;
};
const AlertButtons: FC<Props> = ({ onContinue, onDiscard, discardText, continueText }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.alertButtons}>
      <Button onClick={onContinue} buttonStyle="outlined" color="blue">
        {continueText ?? t('continue_editing')}
      </Button>
      <Button onClick={onDiscard} buttonStyle={'solid'} color="blue">
        {discardText ?? t('discard_changes')}
      </Button>
    </div>
  );
};

export default AlertButtons;
