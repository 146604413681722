import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import dayjs from 'dayjs';

export enum DataHistoryOrderBy {
  ASC = 'asc',
  DESC = 'desc',
}

export enum DataHistorySortKey {
  ACTION_ON = 'action_on',
}

export interface GetHistoryRequest {
  countPerPage: number;
  orderBy: string;
  source_id: string;
  source: string;
}

export interface GetDiffRequest {
  start_id: string;
  end_id: string;
  source_id: string;
  source: string;
}

export interface DataHistoryDiff {
  op: string;
  path: (string | number)[];
  value: string;
  prevValue?: string;
}

export interface DataHistoryDoc {
  _id: string;
  action: string;
  action_on: number;
  display_action_date: string;
  action_by: string;
  source: string;
  resource_id: string;
  changed?: Record<string, unknown>;
}

export const dataHistoryServiceApi = createApi({
  reducerPath: 'dataHistoryServiceApi',
  baseQuery: axiosBaseQuery({
    baseUrl: config.dataHistoryUrl,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getHistory: builder.query<DataHistoryDoc[], GetHistoryRequest>({
      query: (params) => ({
        url: `/history`,
        method: 'GET',
        params,
      }),
      transformResponse: (res: DataHistoryDoc[]) => {
        if (Array.isArray(res)) {
          return res.map((record) => {
            return {
              ...record,
              display_action_date: dayjs(record.action_on).format('MM/DD/YYYY, h:mm a'),
              action_by: record.action_by.includes('==') ? 'Service Update' : record.action_by,
            };
          });
        } else {
          return [];
        }
      },
    }),
    getDiff: builder.query<DataHistoryDiff[], GetDiffRequest>({
      query: (params) => ({
        url: `/diffs`,
        method: 'GET',
        params,
      }),
      transformResponse: (res: DataHistoryDiff[]) => {
        if (Array.isArray(res)) {
          return res;
        } else {
          return [];
        }
      },
    }),
  }),
});

export const { useGetHistoryQuery, useGetDiffQuery } = dataHistoryServiceApi;
