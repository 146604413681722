import css from '../styles/PrimaryNavigation.scss';
import React, { useMemo } from 'react';
import logo from '../../assets/images/rtspro-logo-blue.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { PermissionsCheck } from '../../common/core/Permissions';
import { useAppSelector } from '../../store';
import { isFeatureEnabled } from '../../store/adminShell/selectors';
import { isRepAdminSelector } from '../../store/manageUsers/selectors';
import { t } from 'i18next';

const PrimaryNavigation = () => {
  const userFeatures = useAppSelector((state) => state.adminShell.userFeatures);
  const isRepAdmin = useAppSelector(isRepAdminSelector);
  const cpua4520 = useAppSelector(isFeatureEnabled('CPUA-4520'));
  const cpua3696 = userFeatures.includes('CPUA-3696');
  const cpua3558 = userFeatures.includes('CPUA-3558-WEB');
  const cpua3642 = userFeatures.includes('CPUA-3642');
  const cpua5178 = userFeatures.includes('CPUA-5178');
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const userPermissions = useAppSelector((state) => state.adminShell.user.permissions);
  const validateShowUsersTab = useMemo(() => {
    if (cpua4520 && isRepAdmin) {
      return true;
    } else if (cpua3696 && !isRepAdmin && userPermissions.includes('carrierpro.CompanyAdmin')) {
      return true;
    }
    return false;
  }, [isRepAdmin, cpua4520, userPermissions, cpua3696]);

  return (
    <div className={css.appBar}>
      <nav className={css.toolBar}>
        <h1>
          <img alt="rts-logo" src={logo} className={css.logo} />
        </h1>
        {validateShowUsersTab && (
          <NavLink
            data-joyrideid="UsersNavLink"
            to={AppRoutes.MANAGE_USERS}
            className={({ isActive }) => (isActive ? `${css.navItem} ${css.active}` : css.navItem)}
          >
            Users
          </NavLink>
        )}
        {cpua5178 && (
          <PermissionsCheck
            permissions={userPermissions}
            requiredPermissions={['carrierpro.PermissionsAdmin', 'carrierpro.ViewRoles']}
          >
            <NavLink
              to={AppRoutes.AUTHORIZATION}
              className={({ isActive }) =>
                isActive || path === AppRoutes.AUTHORIZATION ? `${css.navItem} ${css.active}` : css.navItem
              }
            >
              Roles & Permissions
            </NavLink>
          </PermissionsCheck>
        )}
        <PermissionsCheck
          permissions={userPermissions}
          requiredPermissions={['internal.CMS.HomescreenUser', 'internal.CMS.MarketingUser']}
        >
          <NavLink
            to={AppRoutes.CMS}
            className={({ isActive }) =>
              isActive || path === AppRoutes.CMS ? `${css.navItem} ${css.active}` : css.navItem
            }
          >
            Dashboard Cards
          </NavLink>
        </PermissionsCheck>
        {cpua3642 && (
          <PermissionsCheck permissions={userPermissions} requiredPermissions={['carrierpro.RepCompanyAdmin']}>
            <NavLink
              to={AppRoutes.CARRIER_DIRECTORY}
              className={({ isActive }) =>
                isActive || path === AppRoutes.CARRIER_DIRECTORY ? `${css.navItem} ${css.active}` : css.navItem
              }
            >
              Carrier Directory
            </NavLink>
          </PermissionsCheck>
        )}
        <PermissionsCheck permissions={userPermissions} requiredPermissions={['carrierpro.ViewUserManagementTools']}>
          <NavLink
            to={AppRoutes.RTSPRO_TOOLS}
            className={({ isActive }) =>
              isActive || path === AppRoutes.RTSPRO_TOOLS ? `${css.navItem} ${css.active}` : css.navItem
            }
          >
            Tools
          </NavLink>
        </PermissionsCheck>
        {cpua3558 && (
          <NavLink
            to={AppRoutes.CONTACT_US}
            className={({ isActive }) =>
              isActive || path === AppRoutes.CONTACT_US ? `${css.navItem} ${css.active}` : css.navItem
            }
          >
            {t('contact_us')}
          </NavLink>
        )}
      </nav>
    </div>
  );
};

export default PrimaryNavigation;
