import React, { ReactElement } from 'react';
import { checkPermissions } from '../../utils';
import { Navigate } from 'react-router-dom';

interface PermissionsProps {
  permissions: string[];
  requiredPermissions: string[];
  fallback?: string;
  children: ReactElement;
}

export const PermissionsCheck: React.FC<PermissionsProps> = ({
  permissions,
  requiredPermissions,
  fallback,
  children,
}) => {
  if (checkPermissions(permissions, requiredPermissions)) {
    return children;
  } else {
    return fallback ? <Navigate to={fallback} /> : <></>;
  }
};
