import Service from '../service';
import { AxiosResponse } from 'axios';
import { updateUser } from './queries';
import { USER_TYPES } from '../../ManageUsers/constants';
import { UserTrialStatus } from './usersServiceQuery';

export interface User {
  _id: string;
  email: string;
  phone: string;
  permissions: Array<Permission>;
  company_id: string;
  metaData: MetaData;
  company: string;
  firstname: string;
  lastname: string;
  createdOn: string;
  roles: Array<string>;
  user_type?: (typeof USER_TYPES)[number];
  pfjMyRewardsNumber?: string;
  supervisor_email?: string;
  notes?: string;
  bridge_id?: string;
  mcleod_customer_id?: string[];
  company_flagged?: boolean;
  userStatus?: UserTrialStatus;
}

export interface Permission {
  name: string;
}

export interface SeenJoyrides {
  adminManageUsersExternal?: boolean;
}

export interface MetaData {
  unlimitedDevices?: boolean;
  bypassMFA?: boolean;
  seenJoyrides?: SeenJoyrides;
}

export interface SignupExternalUserBody {
  user: {
    email: string;
    ooida_id: string;
    firstname: string;
    lastname: string;
    phone: string;
    company: string;
    password: string;
    roles: string[];
  };
  companyId: string;
}

export interface ForgotPasswordArgs {
  email: string;
  sendSms?: boolean;
}

type UnwantedFields = 'displayName' | 'token';
export type updateUserType = Omit<User, UnwantedFields>;

const { usersServiceUrl: usersServiceUrl } = config;
class UsersService extends Service {
  constructor() {
    super(config.usersServiceUrl);
  }

  public async getUserByEmail(email: string): Promise<{ user: User }> {
    const url = `${usersServiceUrl}/`;

    const body = {
      query: `query user($email: String) {
        user( email: $email) {
          _id,
          email,
          company_id,
          firstname,
          lastname,
          status,
          phone,
          company,
          permissions { name },
          metaData {
            unlimitedDevices,
            seenJoyrides {
              adminManageUsersExternal
            }
          }
        }
      }`,
      variables: {
        email,
      },
    };

    const response: AxiosResponse = await this.axios.post(url, body);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async updateUser(
    user: Partial<Pick<User, 'email' | 'phone'>>,
    changed: Partial<User>,
  ): Promise<{ updateUser: updateUserType }> {
    const url = '/';

    const body = {
      ...updateUser,
      variables: {
        user,
        changed,
      },
    };

    const response: AxiosResponse = await this.axios.post(url, body);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  public async getUsers(emails: Array<string>, phones: Array<string>, length = 2000) {
    const url = `${usersServiceUrl}/`;

    const body = {
      query: `
        query users( $emails: [String], $phones: [String], $length: Int) {
          usersPaged (emails: $emails, phones: $phones, ownerEmail: "", pagedSortRequest: { sortKey: "User", orderBy: ASC, page: 0, countPerPage: $length, } ) {
          email
          phone
        }
      }
      `,
      variables: {
        emails,
        phones,
        length,
      },
    };

    const response = await this.axios.post<{ usersPaged: Array<User> }>(url, body);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  public async forceLogout(email: string, resetPassword: boolean) {
    const url = '/force-logout';

    const body = {
      email,
      resetPassword,
    };

    const response: AxiosResponse = await this.axios.post(url, body);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}

export default UsersService;
