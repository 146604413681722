import { SearchedUser } from '../services/usersService/usersServiceQuery';
import { ValidationErrors } from './core/validation';

export const processAndRemoveElements = <T>(arr: Array<T>, process: (el: T) => void): Array<unknown> => {
  if (arr.length > 0) {
    const el = arr.pop();
    el && process(el);
    return processAndRemoveElements(arr, process);
  }
  return [];
};

export const parseErrors = (errors: ValidationErrors) =>
  errors?.reduce((acc, error) => {
    const key: string = error.schemaPath.includes('required')
      ? (error.params.missingProperty as string)
      : error.instancePath.split('/').pop() ?? 'bad_path';

    return { ...acc, [key]: { message: error.message, keyword: error.keyword } };
  }, {});

export const checkPermissions = (users: Partial<SearchedUser>[]) =>
  users
    .map((user, cardNo) => {
      if (!user.roles || user.roles.length < 1) {
        return cardNo;
      }
      return -1;
    })
    .filter((index) => index !== -1);

// Remember, 0 is false.
export const safeTruthy = (arg: unknown) => typeof arg !== 'undefined' && arg !== null;

export enum AUTHORIZATION_PERMISSIONS {
  MANAGE_ROLE = 'carrierpro.ManageRoles',
}

export enum PERMISSIONS {
  REP_COMPANY_ADMIN = 'carrierpro.RepCompanyAdmin',
}
export function isObjectEmptyStrict<T extends object>(obj: T): boolean {
  const keys = Object.keys(obj);

  if (keys.length === 0) return true;

  return keys.every((key) => !obj[key as keyof T]);
}
