import React from 'react';
import { PaginationFooter } from 'shamrock-clover-ui';
import { useSearchQueryParams } from '../../../utils/hooks';
import { getPageOptions } from '../../../services/usersService/usersServiceQuery';

type Props = {
  onRowsPerPageChange: (page: number) => void;
  onPageChange: (page: number) => void;
  totalItems: number;
};

const Pagination = ({ totalItems, onRowsPerPageChange, onPageChange }: Props) => {
  const { getQueryParam } = useSearchQueryParams();
  const currentPage = parseInt(getQueryParam('page') ?? '1');
  const rowsPerPageQuery = parseInt(getQueryParam('perPage') ?? '10');
  const defaultRows = !getPageOptions().includes(rowsPerPageQuery) && getPageOptions()[0];
  const rowsPerPage = defaultRows || rowsPerPageQuery;

  return (
    <PaginationFooter
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      totalRows={totalItems}
      customRowsPerPageOptions={getPageOptions()}
      onChangePage={(page) => {
        onPageChange(page);
      }}
      onChangeRowsPerPage={(rows) => {
        onRowsPerPageChange(rows);
      }}
      footerOptions={{
        rowsPerPageSelector: true,
        totalRowsDisplay: 'rows',
      }}
    />
  );
};

export default Pagination;
