import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.scss';
import { Icon } from 'shamrock-clover-ui';
import CarrierDirectoryItem from './CarrierDirectoryItem';
import Pagination from '../Pagination/Pagination';
import { useSearchQueryParams } from '../../../utils/hooks';
import { SearchedUser, SortColumns, SortOrder } from '../../../services/usersService/usersServiceQuery';
import PlaceholderCard from './PlaceholderCard';
import Placeholder from '../../../common/components/Placeholder';
import { useSearchURLParams } from '../../../common/utilCustomHooks';

type Props = {
  data: SearchedUser[];
  state: string;
  totalItems?: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (page: number) => void;
};
const CarrierDirectoryList = ({ data, state, totalItems, onRowsPerPageChange, onPageChange }: Props) => {
  const { t } = useTranslation();
  const { getQueryParam } = useSearchQueryParams();
  const sortColumn = (getQueryParam('sort') as SortColumns) ?? SortColumns.Name;
  const sortDirection = (getQueryParam('order') as SortOrder) ?? SortOrder.ASC;

  switch (state) {
    case 'uninitialized':
      return <DefaultState />;
    case 'pending':
      return <LoadingComponent />;
    default:
      if (data.length === 0) {
        return <NotFound />;
      }
      return (
        <>
          <TableHeader sortColumn={sortColumn} sortDirection={sortDirection}>
            <div className={styles['search-banner']}>
              {t('showing_results_for', { count: totalItems })} {getQueryParam('query') || ''}
            </div>
            <div className={styles['table-body']}>
              {data.map((rowData) => (
                <CarrierDirectoryItem key={rowData.email} user={rowData} defaultOpen={data.length === 1} />
              ))}
            </div>
          </TableHeader>
          {totalItems && (
            <div className={styles['pagination-footer']}>
              <Pagination
                totalItems={totalItems}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </>
      );
  }
};

const DefaultState = () => <div className={styles['initial-state']}>Search for a carrier</div>;

const NotFound = () => <div className={styles['initial-state']}>Not found</div>;

const LoadingComponent = () => {
  const { countPerPage: placeholderUsersAmount } = useSearchURLParams();

  const placeholderUsersArray = useMemo(
    () => [...Array(placeholderUsersAmount)].map((v, i) => i),
    [placeholderUsersAmount],
  );
  return (
    <TableHeader>
      <div className={styles['search-banner']}>
        <Placeholder width="20vw" />
      </div>
      <div className={styles['table-body']}>
        {placeholderUsersArray.map((card) => (
          <PlaceholderCard key={card} />
        ))}
      </div>
    </TableHeader>
  );
};

type TableHeaderProps = React.PropsWithChildren & {
  sortDirection?: SortOrder;
  sortColumn?: SortColumns;
};
const TableHeader = ({ children, sortDirection, sortColumn }: TableHeaderProps) => {
  const { t } = useTranslation();
  const { updateQueryParams } = useSearchQueryParams();

  const isSortAscending = sortDirection === SortOrder.ASC;
  const handleSort = (column: SortColumns) => {
    const sortOrder = sortColumn === column && isSortAscending ? SortOrder.DESC : SortOrder.ASC;
    updateQueryParams({ sort: column, order: sortOrder, page: 1 });
  };
  const showIcon = (column: SortColumns): 'dropdownDown' | 'dropdownUp' => {
    const isCurrentColumn = sortColumn === column;
    const iconDirection = isCurrentColumn ? (sortDirection === SortOrder.ASC ? 'Down' : 'Up') : 'Down';
    const iconName: 'dropdownDown' | 'dropdownUp' = `dropdown${iconDirection}`;

    return iconName;
  };
  return (
    <div className={styles.table}>
      <div className={styles['table-header']}>
        <div className={styles['table-header-cell']}>
          <span className={styles['label-header']}>{t('name')}</span>
          <Icon size="12" icon={showIcon(SortColumns.Name)} onClick={() => handleSort(SortColumns.Name)} />
        </div>
        <div className={styles['table-header-cell']}>
          <span className={styles['label-header']}>Company</span>
          <Icon size="12" icon={showIcon(SortColumns.Company)} onClick={() => handleSort(SortColumns.Company)} />
        </div>
        <div className={styles['table-header-cell']}>
          <span className={styles['label-header']}>CRM</span>
        </div>
        <div className={styles['table-header-cell']}>
          <span className={styles['label-header']}>{t('email')}</span>
          <Icon size="12" icon={showIcon(SortColumns.Email)} onClick={() => handleSort(SortColumns.Email)} />
        </div>
        <div className={styles['table-header-cell']}>
          <span className={styles['label-header']}>{t('phone')}</span>
        </div>
        <div className={styles['table-header-cell']}>
          <span className={styles['label-header']}>{t('status')}</span>
        </div>
      </div>
      {children}
    </div>
  );
};

export default CarrierDirectoryList;
