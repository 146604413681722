import React, { FC, useState, useMemo } from 'react';
import { Rep, useGetFactoringRepQuery } from '../../../services/repRetrievalService';
import { ServiceHeader } from './ServiceHeader';
import { SelectInput, Spinner } from 'shamrock-clover-ui';
import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import { useAppSelector } from '../../../store';
import { ServiceRepDetails } from './ServiceRepDetails';
import styles from './repStyles.scss';
import Analytics from '@Common/analytics';

interface AccountRepSelection {
  icon: string;
  serviceName: string;
  data: Rep;
}

export const AccountRepSelection: FC<AccountRepSelection> = ({ icon, serviceName, data }) => {
  const userEmail = useAppSelector((state) => state.adminShell.userEmail);
  const [valueSelected, setValueSelected] = useState(data.activeAccount);
  const [isLoading, setIsLoading] = useState(false);
  const mappedAccounts = useMemo(
    () =>
      data.accounts?.map((value: string): MenuOption => {
        const valueActive = value === data.activeAccount ? value + ' (Active)' : value;
        return { optionName: valueActive, value };
      }),
    [data.accounts],
  );

  const selected = useMemo(
    () => mappedAccounts?.find((account) => account.value === valueSelected),
    [mappedAccounts, valueSelected],
  );

  const onOptionSelected = (value: string) => {
    valueSelected !== value &&
      Analytics.logFirebaseEvent('CONTACT_US', 'ACCOUNT_HELP', 'CLICK', { Description: 'Factoring Account Number' });
    setValueSelected(value);
  };

  const skip = !selected || !data.accounts;
  const { data: [resData] = [] } = useGetFactoringRepQuery(
    { account: selected?.value as string, email: userEmail!, setIsLoading, isExternalUse: true },
    { skip },
  );
  const rep = resData ?? data;

  return (
    <div className={styles.repCard}>
      <ServiceHeader icon={icon} serviceName={serviceName} />
      <div>
        {data.accounts && (
          <div className={styles.accountSelection}>
            <SelectInput
              label="Account Number"
              value={selected}
              onOptionSelected={(option?: MenuOption) => option?.value && onOptionSelected(option.value as string)}
              options={mappedAccounts ?? []}
              variant="filled"
            />
          </div>
        )}
        {isLoading ? (
          <div className={styles.loadingSpinner}>
            <Spinner size="35" />
            <span>Loading...</span>
          </div>
        ) : (
          <ServiceRepDetails rep={rep} serviceName={serviceName} />
        )}
      </div>
    </div>
  );
};
