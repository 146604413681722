import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';

interface ClientAccountGetResponse {
  client_number: string;
  client_name: string;
}

export const internalClientServiceApi = createApi({
  reducerPath: 'internalClientServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.internalClientServiceUrl }),
  endpoints: (builder) => ({
    getClientAutocomplete: builder.query<ClientAccountGetResponse[], string>({
      query: (clientNumber) => ({
        method: 'GET',
        url: `client-autocomplete/?searchValue=${clientNumber}&limit=1000`,
      }),
    }),
  }),
});

export const { useLazyGetClientAutocompleteQuery } = internalClientServiceApi;
