import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import { RootState } from '../../store';

export interface Rep {
  product: string;
  name: string;
  phone: string;
  ext: string;
  email: string;
  office: string;
  timezone: string;
  accounts?: Array<string>;
  activeAccount?: string;
}

interface FactoringRep {
  name: string;
  phone: string;
  email: string;
  ext: string;
  office: string;
  timezone: string;
}

export const repRetrievalApi = createApi({
  reducerPath: 'repRetrievalApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.repRetrievalServiceUrl }),
  endpoints: (builder) => ({
    getReps: builder.query<Rep[], string>({
      query: (email) => ({
        url: `?email=${encodeURIComponent(email)}`,
        method: 'GET',
      }),
    }),
    getRepsExternalUse: builder.query<Rep[], string>({
      query: (email) => ({
        url: `/external-use?email=${encodeURIComponent(email)}`,
        method: 'GET',
      }),
    }),
    getFactoringRep: builder.query<
      Rep[],
      {
        account: string;
        email: string;
        setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
        isExternalUse?: boolean;
      }
    >({
      async queryFn({ account, email, setIsLoading, isExternalUse }, api, _extra, fetch) {
        setIsLoading(true);
        const state = api.getState() as RootState;
        const { data } = isExternalUse
          ? repRetrievalApi.endpoints.getRepsExternalUse.select(email)(state)
          : repRetrievalApi.endpoints.getReps.select(email)(state);
        const { data: factoringRep } = await fetch({ url: `?account=${account}`, method: 'GET' });
        const repsResponse: Rep[] = data
          ? [
              {
                product: 'RTS Financial',
                accounts: data[0].accounts,
                activeAccount: data[0].activeAccount,
                ...(factoringRep as FactoringRep),
              },
              ...data.slice(1),
            ]
          : [];
        setIsLoading(false);
        return { data: repsResponse };
      },
    }),
  }),
});

export const { useGetRepsQuery, useGetRepsExternalUseQuery, useGetFactoringRepQuery } = repRetrievalApi;
