import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import { AxiosError } from 'axios';

interface FactoringAccount {
  reference_id: string;
}

export interface IMUser {
  user_id: string;
  accounts: FactoringAccount[];
  active_account: string;
}

export interface IMUserPatch extends IMUser {
  email: string;
  active_account: string;
  replace: boolean;
}
export const invoiceManagerUsersServiceApi = createApi({
  reducerPath: 'invoiceManagerUsersServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.invoiceManagerUserServiceUrl }),
  tagTypes: ['GetIMUser'],
  endpoints: (builder) => ({
    getIMUser: builder.query<IMUser, string>({
      query: (email) => ({
        method: 'GET',
        url: `${email}`,
      }),
      providesTags: ['GetIMUser'],
    }),
    saveIMUser: builder.mutation<any, IMUserPatch>({
      queryFn: async ({ email, user_id, active_account, accounts, replace }, _, __, baseQuery) => {
        let replaceString = '';
        if (replace) {
          replaceString = '?replace=true';
        }
        const patchUrl = `${email}${replaceString}`;
        try {
          const result = await baseQuery({
            method: 'PATCH',
            url: patchUrl,
            data: {
              user_id,
              active_account,
              accounts,
            },
          });
          return { data: result.data };
        } catch (axiosError) {
          const err = axiosError as AxiosError;
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      },
      invalidatesTags: ['GetIMUser'],
    }),
  }),
});

export const { useGetIMUserQuery, useSaveIMUserMutation } = invoiceManagerUsersServiceApi;
