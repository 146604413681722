import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppSelector } from '..';

const getNavigation = (state: RootState) => state.adminShell.subNav;

type Tab = {
  title: string;
  onClick: () => void;
};

type UseSubNavigationReturnType = [Tab[], number];

export const useSubNavigation = (): UseSubNavigationReturnType => {
  const subNavs = useAppSelector(getNavigation); // Replace with the actual selector function
  const navigate = useNavigate();
  const location = useLocation();
  const [, , subNavCurrentPath] = location.pathname.split('/');
  const currentIndex = subNavs.map(({ path }) => path.split('/').pop()).indexOf(subNavCurrentPath);
  const tabs: Tab[] = subNavs.map(({ title, path }) => ({
    title,
    onClick: () => navigate(path),
  }));
  return [tabs, currentIndex];
};
export const isFeatureEnabled = (feature: string) => (state: RootState) => {
  const userFeatures = state.adminShell.userFeatures;
  return userFeatures.includes(feature);
};
