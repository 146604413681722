// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdditionalAction__manageUsersButton___MeQL6 {
  display: flex;
  border: unset;
  background-color: unset;
  color: #0091EA;
  cursor: pointer;
  padding: 0;
  margin-right: 32px;
  font-size: 14px;
  align-items: center;
}
.AdditionalAction__manageUsersButton___MeQL6:hover {
  color: #005C95;
  text-decoration: underline;
}
.AdditionalAction__manageUsersButton___MeQL6:hover .AdditionalAction__joyrideIcon___lZ0I8 {
  fill: #005C95;
}
.AdditionalAction__manageUsersButton___MeQL6 .AdditionalAction__joyrideIcon___lZ0I8 {
  padding-left: 8px;
  width: fit-content;
  fill: #0091EA;
}`, "",{"version":3,"sources":["webpack://./src/AdminShell/styles/AdditionalAction.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,cCFc;EDGd,eAAA;EACA,UAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;AADF;AAEE;EACE,cCPiB;EDQjB,0BAAA;AAAJ;AACI;EACE,aCVe;ADWrB;AAEE;EACE,iBAAA;EACA,kBAAA;EACA,aClBY;ADkBhB","sourcesContent":["@use '../../variables.scss';\n\n.manageUsersButton {\n  display: flex;\n  border: unset;\n  background-color: unset;\n  color: variables.$shamrock-blue;\n  cursor: pointer;\n  padding: 0;\n  margin-right: 32px;\n  font-size: 14px;\n  align-items: center;\n  &:hover {\n    color: variables.$shamrock-dark-blue;\n    text-decoration: underline;\n    .joyrideIcon {\n      fill: variables.$shamrock-dark-blue;\n    }\n  }\n  .joyrideIcon {\n    padding-left: 8px;\n    width: fit-content;\n    fill: variables.$shamrock-blue;\n  }\n}\n","$font-color-dark: #333;\n$font-color-light: #666;\n$border-color-light: #CCC;\n$border-color-lighter: #EEE;\n$shamrock-blue: #0091EA;\n$shamrock-light-blue: #EAF7FF;\n$shamrock-dark-blue: #005C95;\n$shamrock-dark-orange: #CC5F00;\n$admin-shell-height: 146px;\n$contact-us-container-width: 747px;\n$secondary-nav-height: 40px;\n$white: #ffff;\n$gray-10: #F9F9F9;\n$error-red: #EF3824;\n\n@mixin text-standard {\n    font-family: proxima-nova, arial, sans-serif;\n    color: $font-color-dark;\n    text-decoration: none;\n}\n\n@mixin addHeaderMargin {\n    margin-top: $admin-shell-height;\n}\n\n@mixin tinySpinnerFix {\n    > div > div {\n        display: inline-flex;\n        justify-items: center;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageUsersButton": `AdditionalAction__manageUsersButton___MeQL6`,
	"joyrideIcon": `AdditionalAction__joyrideIcon___lZ0I8`
};
export default ___CSS_LOADER_EXPORT___;
