import type { Role } from '../../constants';
import Service from '../service';

const { companyServiceUrl } = config;

// TODO: Move to tokenService once it exists in src/services
interface SignupToken {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  admin_invite: boolean;
  user_type: string;
  roles: Array<string>;
  company: string;
  company_type: string;
  supervisor_email: string;
}

// TODO: Move to rolesService once it exists in src/services
interface RolesService {
  getBaseRoles: () => Promise<(undefined | Role | Array<undefined | Role>)[]>;
}

interface User {
  email: string;
  name: string;
  phone: string;
  status: string;
  permissions: Array<string>;
  createdAt: string;
  lastUsed: string;
}

export interface CompanyResponse {
  _id: string;
  company_id: string;
  company_name: string;
  company_email: string;
  users: Array<User>;
  pending_users: Array<string>;
  roles: Array<string>;
  notes: string;
  flagged?: boolean;
  // Has more fields, but we don't care right now.
}

export interface CompositionExternalBody {
  active: number;
  inactive: number;
  pendingInvites: number;
  userCount: number;
}
export interface CompositionInternalBody extends CompositionExternalBody {
  unmatched: number;
}
export interface CompanyCompositionResponseQuery {
  companyComposition: CompositionExternalBody;
}
export interface UsersCompositionResponseQuery {
  usersComposition: CompositionInternalBody;
}

export interface Company extends Omit<CompanyResponse, 'roles'> {
  roles: Array<Role>;
}

export const isCompanyLiteValidation = (company?: Company) => {
  return company?.company_id.startsWith('L');
};
export class CompanyModel implements Omit<Company, '_id'> {
  public readonly _id: string;
  public readonly company_id: string;
  public readonly company_name: string;
  public readonly company_email: string;
  public readonly users: Array<User>;
  public readonly pending_users: Array<string>;
  public readonly roles: Array<Role>;
  public readonly notes: string;
  public readonly flagged?: boolean;

  constructor(company: Company) {
    this._id = company._id;
    this.company_id = company.company_id;
    this.company_name = company.company_name;
    this.company_email = company.company_email;
    this.users = company.users;
    this.roles = company.roles;
    this.notes = company.notes;
    this.flagged = company.flagged;
    this.pending_users = company.pending_users;
  }
  public get rolesNames() {
    return this.roles.map((role) => role.name);
  }
}

export class CompanyService extends Service {
  // Currently optional as rolesService doesn't exist in src/services
  // and is only used in src/Tools/MassInvite
  private readonly rolesService?: RolesService;

  constructor(rolesService?: RolesService) {
    super(companyServiceUrl);
    this.rolesService = rolesService;
  }

  public getCompany(companyId: string) {
    return this.axios.get<Array<CompanyResponse>>('', { params: { company_id: companyId } });
  }

  public getCompanyByName(companyName: string) {
    return this.axios.get<Array<CompanyResponse>>('', { params: { company_name: companyName } });
  }

  public getCompanyWithUsers(companyId: string) {
    return this.axios.get<CompanyResponse>(`/users/${companyId}`);
  }

  public async getCompanyByEmail(email: string) {
    return (await this.axios.get<Array<CompanyResponse>>(`/${email}`)).data;
  }

  public async getCompanyWithBaseRoles(companyId: string) {
    if (!this.rolesService) throw new Error('No RolesService provided to CompanyService');
    const { data } = await this.getCompany(companyId);
    if (data?.length === 0) throw new Error('Could not find company');
    const [{ roles: companyRoles, ...company }] = data;
    const baseRoles = await this.rolesService.getBaseRoles();
    if (!companyRoles || companyRoles.length === 0) {
      throw new Error('Company has no roles');
    }
    const companyBaseRoles = baseRoles
      .filter((role) =>
        Array.isArray(role) ? companyRoles.includes(role.at(0)?._id ?? '') : companyRoles.includes(role?._id ?? ''),
      )
      .flat();

    if (companyBaseRoles.length === 0) {
      throw new Error('Company does not have the correct Base roles');
    }
    return new CompanyModel({ ...company, roles: companyBaseRoles as Role[] });
  }

  public async addUsersToCompany(companyId: string, addUsers: Array<SignupToken>) {
    const users = addUsers
      .filter((user) => Boolean(user.email))
      .map(({ email }) => ({
        email,
      }));
    const pending_users = addUsers.filter((user) => Boolean(user.phone && !user.email)).map(({ phone }) => phone);
    const result = await this.axios.patch(`/${companyId}`, { company_id: companyId, users, pending_users });
    return result;
  }

  public async updateCompanyNotes(companyId: string, notes: string) {
    const result = await this.axios.patch(`/${companyId}`, { company_id: companyId, notes });
    return result;
  }

  public async flagCompany(companyId: string, flagged: boolean) {
    const result = await this.axios.patch(`/${companyId}`, { company_id: companyId, flagged });
    return result;
  }

  public async changeUserCompany(user: string, companyId: string) {
    return await this.axios.patch(`/change/${companyId}/user/${user}`);
  }

  public async mergeCompanies(companyIdToDelete: string, companyIdToKeep?: string) {
    return this.axios.delete('/merge', {
      data: {
        merge: Boolean(companyIdToKeep),
        companyToDelete: companyIdToDelete,
        companyToKeep: companyIdToKeep,
      },
    });
  }
}
