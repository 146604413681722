import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-request';
import { Dispatch } from 'redux';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { User } from '.';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { type RootState } from '../../store';
import { invalidateCompanyTags } from './companyCompositionQuery'; // Import the API from which you want to invalidate the tag
import { carrierDirectorySearch, manageUsersSearch } from './queries';
import type { Role } from '../../constants';
import { handleErrors } from '../serviceRtkQuery';

const removeKeysList = [
  'status',
  'userStatus',
  'trialDaysLeft',
  'acceptedLatestTos',
  'partialPhone',
  'devices',
  'loginFailure',
  'associatedCompanies',
  'device_info',
  'trials',
  'createdOn',
];

export const getPageOptions = (isRepAdmin?: boolean) => (isRepAdmin ? [10, 25, 50, 100] : [10, 25, 50]);

export enum FilterType {
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
  Unmatched = 'unmatched',
}

export enum SortColumns {
  Name = 'name',
  Email = 'email',
  Company = 'company',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Invite Pending',
  Unmatched = 'Unmatched',
  TrialUser = 'Trial User',
}

export enum TrialType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
}

export interface UserTrialStatus {
  trialState: TrialType;
  trialDaysLeft: number;
  isOnCompany: boolean;
  isRegistered: boolean;
  isTrial: boolean;
}

export interface UpdateTrialRequest {
  email: string;
  requestedBy: string;
  expire: boolean;
}

interface Trial {
  createdOn: string;
  createdBy: string;
  expiration: string;
}

export type SearchedUser = Omit<User, '_id' | 'permissions' | 'metaData' | 'firstname' | 'lastname' | 'roles'> & {
  _id?: string;
  name: string;
  createdOn: string;
  lastUsed: string;
  status: UserStatus;
  roles: Array<Role>;
  device_info?: {
    devices_by_language: DeviceInformation[];
  };
  mcleod_customer_id?: string[];
  company_flagged?: boolean;
  userStatus?: UserTrialStatus;
  trials?: Trial[];
  company_type?: string;
};

export interface DeviceInformation {
  rtspro_language: string;
  device_count: string;
}

export interface ForcedLogoutUserDetails extends Pick<User, 'email'> {
  loginFailure: {
    loginAttempts: number;
    lastError: 'code' | 'password';
  };
  devices: {
    mobileCount: number;
    webCount: number;
  };
  associatedCompanies: Array<{
    companyId: string;
    companyName: string;
  }>;
}
export interface GetUserRequest {
  email: string;
}

export type SearchResult = { users: SearchedUser[]; totalItems: number };
export interface UserSearchResponse {
  search: SearchResult;
}

export interface SearchRequest {
  search?: string;
  companyId?: string;
  status?: FilterType;
  includeDeviceInfo?: boolean;
  focusedEmail?: string;
  pagedSortRequest: {
    sortKey?: SortColumns;
    orderBy: SortOrder;
    page: number;
    countPerPage: number;
  };
  // Arbitrary field as a workaround for the issue listed here: https://github.com/reduxjs/redux-toolkit/issues/2802
  issue2802WorkAround?: number;
}

export interface ChangedUser extends Partial<Omit<SearchedUser, 'roles' | 'createdOn' | 'name' | 'lastUsed'>> {
  roles?: Array<string>;
  firstname?: string;
  lastname?: string;
}

export type ChangedUserRequest = Array<{
  userInput: {
    email?: string;
    phone?: string;
  };
  changed: ChangedUser;
}>;
export interface UpdateRequest {
  users: ChangedUserRequest;
  skipSearchCacheInvalidate?: boolean;
}
export type DeleteUserInput = UserInputPhone | UserInputEmail | (UserInputPhone & UserInputEmail);
interface UpdateResult {
  updateUsersAndSignupTokens: {
    changed: User;
    message: string;
  }[];
}
interface UserInputPhone {
  phone: string;
}

interface UserInputEmail {
  email: string;
}

export interface DeleteUserOrPending {
  userInput: DeleteUserInput;
  company_id: string;
}

export interface SoftDeleteUserProps {
  matchedUsers: DeleteUserOrPending[];
  pendingUsers: DeleteUserOrPending[];
}

interface SoftDeleteResponse {
  unmatchedAmount: number;
}

export const userServiceRTKApi = createApi({
  reducerPath: 'userServiceSearchApi',
  baseQuery: graphqlRequestBaseQuery({
    requestHeaders: {
      'gql-data-return': 'true',
    },
    prepareHeaders: async (headers, { getState }) => {
      const adminShellState = (getState() as RootState).adminShell;
      const getAccessToken = adminShellState?.getAccessToken;
      const oldToken = LocalAuth.getTokenValue();
      let token = oldToken;
      const isAuth0Enabled = adminShellState?.userFeatures?.includes(
        '43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd',
      );
      if (!token && getAccessToken && isAuth0Enabled) token = await getAccessToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
    customErrors: async (error) => {
      await handleErrors(error);
    },
    url: config.usersServiceUrl,
  }),
  invalidationBehavior: 'delayed',
  tagTypes: ['Search'],
  keepUnusedDataFor: 15,
  endpoints: (builder) => ({
    userSearch: builder.query<SearchResult, SearchRequest>({
      query: ({
        search,
        companyId,
        pagedSortRequest: { page, ...pagedRequest },
        status,
        focusedEmail,
        includeDeviceInfo,
      }) => {
        const parsedStatus = mapStatus(status);
        const normalPage = page === 0 ? 0 : page - 1;
        const query = includeDeviceInfo ? carrierDirectorySearch.query : manageUsersSearch.query;
        return {
          document: gql`
            ${query}
          `,
          variables: {
            search,
            status: parsedStatus,
            pagedSortRequest: { ...pagedRequest, page: normalPage },
            companyId,
            focusedEmail,
          },
        };
      },
      providesTags: ['Search'],
      transformResponse(response: UserSearchResponse) {
        return response.search;
      },
    }),
    getUser: builder.query<ForcedLogoutUserDetails, string>({
      query: (email) => {
        return {
          document: gql`
            query User($email: String) {
              user(email: $email) {
                email
                devices {
                  mobileCount
                  webCount
                }
                loginFailure {
                  loginAttempts
                  lastError
                }
                associatedCompanies {
                  companyId
                  companyName
                }
              }
            }
          `,
          variables: {
            email,
          },
        };
      },
      transformResponse(response: { user: ForcedLogoutUserDetails }) {
        return response.user;
      },
    }),
    updateUsers: builder.mutation<UpdateResult, UpdateRequest>({
      query: ({ users: unmodifiedUsers }) => {
        const users = unmodifiedUsers.map(({ userInput, changed }) => {
          const modifiedUser: { [key: string]: unknown } = {};
          Object.entries(changed).forEach(([key, val]) => {
            // Remove keys before sending the request
            if (removeKeysList.includes(key)) return;
            // Omit the company_id for company lite users
            if (key === 'company_id' && val?.startsWith('L')) return;
            if (key === 'email') {
              modifiedUser[key] = val;
              return;
            }
            // Convert undefined to null so that it doesn't get removed from the variables
            modifiedUser[key] = val ?? null;
          });
          return { userInput, changed: modifiedUser };
        });

        return {
          document: gql`
            mutation UpdateUsersAndSignupTokens($users: [UserInfo]) {
              updateUsersAndSignupTokens(users: $users) {
                message
                changed {
                  email
                  firstname
                  lastname
                  company
                  company_type
                  createdOn
                  phone
                  roles
                  status
                  trialDaysLeft
                  user_type
                  company_id
                  trialExtensionRequested
                  driverId
                  pfjMyRewardsNumber
                  mc_number
                  mcleod_customer_id
                  bridge_id
                  productRequests
                  supervisor_email
                  tmsGetStarted
                  token
                  fraudInviteStatus
                }
              }
            }
          `,
          variables: {
            users,
          },
        };
      },
      onQueryStarted: ({ skipSearchCacheInvalidate }, { dispatch, queryFulfilled }) => {
        if (!skipSearchCacheInvalidate) {
          invalidateUserTags({ dispatch, queryFulfilled }, ['Search']);
        }
      },
    }),
    deactivateUser: builder.mutation<
      string,
      { roles?: string[]; isRepAdmin?: boolean; emails: string[]; phones: string[] }
    >({
      query: ({ roles, phones, emails }) => {
        return {
          document: gql`
            mutation UpdateUsers($emails: [String], $phones: [String], $rolesToRemove: [String]) {
              updateUsers(emails: $emails, phones: $phones, rolesToRemove: $rolesToRemove)
            }
          `,
          variables: {
            emails,
            phones,
            rolesToRemove: roles ?? [],
          },
        };
      },
      invalidatesTags: ['Search'],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        invalidateCompanyTags(_, { dispatch, queryFulfilled }, ['userComposition', 'companyComposition']);
      },
    }),
    softDeleteUser: builder.mutation<SoftDeleteResponse, SoftDeleteUserProps>({
      query: ({ matchedUsers, pendingUsers }) => {
        return {
          document: gql`
            mutation softDeleteUsers($matchedUsers: [DeleteUserOrPending], $pendingUsers: [DeleteUserOrPending]) {
              softDeleteUsers(matchedUsers: $matchedUsers, pendingUsers: $pendingUsers) {
                unmatchedAmount
              }
            }
          `,
          variables: {
            matchedUsers: matchedUsers ?? [],
            pendingUsers: pendingUsers ?? [],
          },
        };
      },
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        invalidateCompanyTags(_, { dispatch, queryFulfilled }, ['userComposition', 'companyComposition']);
      },
      invalidatesTags: ['Search'],
    }),
    activateUser: builder.mutation<string, { email: string; roles: Role[] }>({
      query: ({ email, roles }) => {
        return {
          document: gql`
            mutation UpdateUsers($emails: [String], $rolesToAdd: [String]) {
              updateUsers(emails: $emails, rolesToAdd: $rolesToAdd)
            }
          `,
          variables: {
            emails: [email],
            rolesToAdd: getDefaultRoles(roles).map((role) => role._id),
          },
        };
      },
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        invalidateCompanyTags(_, { dispatch, queryFulfilled }, ['userComposition', 'companyComposition']);
      },
      invalidatesTags: ['Search'],
    }),
    batchUserRolesUpdate: builder.mutation<string, { emails: string[]; phones: string[]; roles: Role[] }>({
      query: ({ emails, phones, roles }) => {
        return {
          document: gql`
            mutation UpdateUsers($emails: [String], $phones: [String], $rolesToAdd: [String]) {
              updateUsers(emails: $emails, phones: $phones, rolesToAdd: $rolesToAdd)
            }
          `,
          variables: {
            emails,
            phones,
            rolesToAdd: roles.map((role) => role._id),
          },
        };
      },
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        invalidateCompanyTags(_, { dispatch, queryFulfilled }, ['companyComposition']);
      },
      invalidatesTags: ['Search'],
    }),
    hardDeleteUser: builder.mutation<string, string>({
      query: (email) => {
        return {
          document: gql`
            mutation DeleteUser($email: String) {
              deleteUser(email: $email)
            }
          `,
          variables: {
            email,
          },
        };
      },
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        invalidateCompanyTags(_, { dispatch, queryFulfilled }, ['userComposition']);
      },
      invalidatesTags: ['Search'],
    }),
    updateTrial: builder.mutation<string, UpdateTrialRequest>({
      query: ({ email, requestedBy, expire }) => {
        return {
          document: gql`
            mutation UpdateTrial($email: String, $requestedBy: String, $expire: Boolean) {
              updateTrial(email: $email, requestedBy: $requestedBy, expire: $expire)
            }
          `,
          variables: {
            email,
            requestedBy,
            expire,
          },
        };
      },
      invalidatesTags: ['Search'],
    }),
  }),
});

// Utils
export const mapStatus = (key?: FilterType) =>
  key
    ? Reflect.get(
        {
          [FilterType.Active]: UserStatus.Active,
          [FilterType.Inactive]: UserStatus.Inactive,
          [FilterType.Pending]: UserStatus.Pending,
          [FilterType.Unmatched]: UserStatus.Unmatched,
        },
        key,
      )
    : '';

export const getDefaultRoles = (roles: Array<Role>): Role[] => roles.filter((role) => role.default_invite_role);

export async function invalidateUserTags(
  { dispatch, queryFulfilled }: { dispatch: Dispatch; queryFulfilled: Promise<unknown> },
  tags: 'Search'[],
) {
  try {
    const res = await queryFulfilled;
    if (res) dispatch(userServiceRTKApi.util.invalidateTags(tags));
  } catch (e) {
    console.error(e);
  }
}

export const {
  useUserSearchQuery,
  useLazyGetUserQuery,
  useLazyUserSearchQuery,
  useDeactivateUserMutation,
  useActivateUserMutation,
  useSoftDeleteUserMutation,
  useUpdateUsersMutation,
  useBatchUserRolesUpdateMutation,
  useHardDeleteUserMutation,
  useUpdateTrialMutation,
} = userServiceRTKApi;
