import React, { useCallback, useState } from 'react';
import CarrierDirectoryList from './components/CarrierDirectoryList/CarrierDirectoryList';
import { FilterInput } from 'shamrock-clover-ui';
import styles from './styles.scss';
import { useSearchQueryParams } from '../utils/hooks';
import { FilterType, SortColumns, SortOrder, useUserSearchQuery } from '../services/usersService/usersServiceQuery';
import { useSearchURLParams } from '../common/utilCustomHooks';
import { debounce } from '@shamrock-core/common/utils';
import { useAppSelector } from '../store';
import Analytics from '@Common/analytics';

export const CarrierDirectory = () => {
  const { updateQueryParams, getQueryParam } = useSearchQueryParams();
  const [search, setSearch] = useState(getQueryParam('query') || '');
  const { query, status: _, crm: _x, ...pagedSortRequest } = useSearchURLParams();
  const user = useAppSelector((state) => state.adminShell.user);
  const defaultValues: Omit<typeof pagedSortRequest, 'crm'> = {
    page: 1,
    countPerPage: 10,
    sortKey: SortColumns.Name,
    orderBy: SortOrder.ASC,
  };
  const { data: { users, totalItems } = {}, status } = useUserSearchQuery(
    {
      search: query,
      status: FilterType.Active,
      pagedSortRequest: { ...pagedSortRequest, sortKey: pagedSortRequest.sortKey ?? SortColumns.Name },
      includeDeviceInfo: true,
    },
    {
      skip: !query.length,
    },
  );

  const setSearchQueryParamDebounce = useCallback(
    debounce((query) => {
      query != '' &&
        Analytics.logFirebaseEvent('ADMINISTRATION', 'CARRIER_DIRECTORY', 'SEARCH', {
          dateTimestamp: new Date().toISOString(),
          mongoUserId: user._id,
          searchValue: query,
        });
      updateQueryParams({ query, ...defaultValues });
    }, 500),
    [updateQueryParams],
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setSearchQueryParamDebounce(e.target.value);
  };

  const onRowsPerPageChange = (rows: number) => {
    window.scroll({ top: 0, left: 0 });
    updateQueryParams({ perPage: rows, page: 1 });
  };
  const onPageChange = (page: number) => {
    window.scroll({ top: 0, left: 0 });
    updateQueryParams({ page: page-- });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Find Carrier Details</div>
      <div className={styles.filterInputWrapper}>
        <FilterInput
          value={search}
          onChange={handleOnChange}
          width="584"
          clearSearch={() => setSearch('')}
          icon="searchBlack"
          className={styles.filterInput}
          placeholder="Search by User, CRM, Company Name, MC#"
        />
      </div>
      <CarrierDirectoryList
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        data={users ?? []}
        state={status}
        totalItems={totalItems}
      />
    </div>
  );
};
