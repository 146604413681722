import FirebaseAnalytics from '@shamrock-core/common/analytics/firebase-analytics';

type FireBaseModules = typeof FirebaseAnalytics.MODULES;
type FireBasePages = typeof FirebaseAnalytics.PAGES;
type FireBaseEvents = typeof FirebaseAnalytics.EVENTS;

type FireBaseParameters = {
  mongoUserId?: string;
  dateTimestamp?: string;
  searchValue?: string;
  Description?: string;
  Type?: string;
};

class Analytics extends FirebaseAnalytics {
  static logFirebaseEvent = (module: string, page: string, event: string, params: FireBaseParameters) => {
    FirebaseAnalytics.sendEvent(this.EVENTS[event], this.MODULES[module], this.PAGES[page], {
      ...params,
    });
  };

  static get MODULES(): FireBaseModules {
    return {
      ...super.MODULES,
      ADMIN_MANAGE_USERS: 'Manage Users',
      ADMINISTRATION: 'Administration',
      CONTACT_US: 'Contact Us-Web',
      MANAGE_USERS: 'manageUsers',
    };
  }

  static get PAGES(): FireBasePages {
    return {
      ...super.PAGES,
      ACCOUNT_HELP: 'Account Help',
      ADMIN_INVITE_NEW_USER: 'inviteConfirmation',
      ADVANCEMENT_DETAILS: 'advancementDetails',
      ALL_USERS: 'allUsers',
      CARRIER_DIRECTORY: 'carrier-directory',
      COMPANY_USERS: 'companyUsers',
      FAQ: 'FAQ',
      INVITE_CONFIRMATION: 'Invite Confirmation',
      TECHNICAL_HELP: 'Technical Help',
      VIEW_PDF: 'viewPdf',
      WEB_USERS_PAGE: 'Web Users page',
    };
  }

  static get EVENTS(): FireBaseEvents {
    return {
      ...super.EVENTS,
      SEARCH: 'Search',
      CLICK: 'Click',
      CLICKV2: 'click',
    };
  }
}

export default Analytics;
