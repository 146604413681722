import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { CompanyResponse } from '../../services/companyService';
import { SearchedUser } from '../../services/usersService/usersServiceQuery';
import { User } from '../../services/usersService';
import { checkPermissions } from '@Common/utils';
import type { Role } from '../../constants';
import dayjs from 'dayjs';
import { ValidationErrors } from '@Common/core/validation';

export enum SharedEditPanelRoute {
  SUCCESS_SCREEN = 'SUCCESS_SCREEN',
  ERROR_SCREEN = 'ERROR_SCREEN',
  LOADING = 'LOADING',
  DYNAMIC_ERROR_SCREEN = 'DYNAMIC_ERROR_SCREEN',
}

export enum SingleEditPanelRoute {
  EDIT_USER = 'EDIT_USER',
  ACTIVATE_USER = 'ACTIVATE_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  EXTEND_TRIAL = 'EXTEND_TRIAL',
  SEND_PASSWORD_RESET = 'SEND_PASSWORD_RESET',
  DELETE_USER = 'DELETE_USER',
  USER_DELETED_SUCCESS = 'USER_DELETED_SUCCESS',
  SEND_ONBOARDING = 'SEND_ONBOARDING',
  SEND_INVITE = 'SEND_INVITE',
  INVITATIONS = 'INVITATIONS',
  SEND_INVITATION = 'SEND_INVITATION',
  CANCEL_INVITATION = 'CANCEL_INVITATION',
  RESEND_INVITATION = 'RESEND_INVITATION',
  CONFIRMATION_SCREEN = 'CONFIRMATION_SCREEN',
  REQUEST_CHANGE_SCREEN = 'REQUEST_CHANGE_SCREEN',
  VIEW_PDF = 'VIEW_PDF',
}

export enum BatchEditPanelRoute {
  ADD_PERMISSIONS = 'ADD_PERMISSIONS',
  ASSIGN_TO_COMPANY = 'ASSIGN_TO_COMPANY',
  ASSIGN_BATCH_PROCESSING = 'ASSIGN_BATCH_PROCESSING',
  ASSIGN_BATCH_RESULTS = 'ASSIGN_BATCH_RESULTS',
  MAIN_MENU = 'MAIN_MENU',
  BATCH_RESEND_INVITES = 'BATCH_RESEND_INVITES',
  DEACTIVATE_USER = 'DEACTIVATE_USERS',
  RESET_PERMISSIONS = 'RESET_PERMISSIONS',
  BATCH_DELETE_USERS = 'BATCH_DELETE_USERS',
}

export enum CardAreaRoute {
  MANAGE_USERS,
  ADD_USERS,
}

export enum AddUserRoute {
  BASE_SELECT,
  PERMISSION_SELECT,
  NO_CARDS,
  LOADING,
  ERROR,
  SUCCESS_SCREEN,
}

export type PanelState = {
  panelRoute?: SingleEditPanelRoute | BatchEditPanelRoute | SharedEditPanelRoute;
  closeDelay: number;
  routeParams?: Record<string, unknown>;
};
export type AddUser = Partial<SearchedUser> & { key?: number; defaultPermissionsSetted?: boolean };
export type AddUsers = AddUser[];

export type CardAreaState = {
  cardAreaRoute: CardAreaRoute;
  addedUsers: {
    users: AddUsers;
    errors: Record<string, string>[];
    validationErrors: Array<ValidationErrors | Record<string, string>[]>;
  };
  selectedIndex: number;
};

export type ChangeCompanyState = {
  loading?: boolean;
  companyId?: string;
  invalid?: boolean;
  originalId?: string;
  remove?: boolean;
};

export type AddUserState = {
  addUserRoute?: AddUserRoute;
  showConfirmation?: boolean;
  permissionErrors?: number[];
  permissionSelected?: PermissionSelection;
  inviteErrors?: {
    failures?: Partial<User>[];
    error?: boolean;
  };
  closeInternalCompanyView?: boolean;
};

export enum PermissionSelection {
  SAME_PERMISSIONS = 'SAME_PERMISSIONS',
  UNIQUE_PERMISSIONS = 'UNIQUE_PERMISSIONS',
}

export type SearchedUserWithInvitationsReloadDate = SearchedUser & {
  lastInvitationsReloadDate?: dayjs.Dayjs;
};

export interface ManageUsersState {
  company?: CompanyResponse;
  showUserEditPanel: boolean;
  selectedUsers: Array<SearchedUserWithInvitationsReloadDate>;
  editUserRoute: PanelState;
  isSelectAllLoading?: boolean;
  cardAreaState: CardAreaState;
  addUserState: AddUserState;
  quickActionMessage: string;
  showCancelEdit: boolean;
  showCancelBatch: boolean;
  formState: {
    hasChanges: boolean;
    initialUserState?: SearchedUser;
    valid: boolean;
    showAlert: boolean;
    alertAction?: 'confirm' | 'deny';
    changeCompany?: ChangeCompanyState;
  };
  confirmationModalShow: boolean;
  isContinueConfirmationModal: boolean;
  lastReloadDate?: dayjs.Dayjs;
  isInternalSingle: boolean;
  isAnimationLoading: boolean;
}

const initialState: ManageUsersState = {
  selectedUsers: [],
  showUserEditPanel: false,
  editUserRoute: {
    closeDelay: 0,
  },
  showCancelEdit: false,
  showCancelBatch: false,
  cardAreaState: {
    cardAreaRoute: CardAreaRoute.MANAGE_USERS,
    addedUsers: {
      users: [],
      errors: [],
      validationErrors: [],
    },
    selectedIndex: 0,
  },
  addUserState: {
    addUserRoute: AddUserRoute.BASE_SELECT,
    showConfirmation: false,
    closeInternalCompanyView: false,
  },
  quickActionMessage: '',
  formState: {
    hasChanges: false,
    initialUserState: {} as SearchedUser,
    showAlert: false,
    valid: false,
  },
  confirmationModalShow: false,
  isContinueConfirmationModal: false,
  isInternalSingle: false,
  isAnimationLoading: false,
};
export const {
  actions: {
    selectUser,
    deselectUser,
    closePanel,
    openEditUsers,
    setPanelRoute,
    setSelectedUsers,
    setFormHasChanges,
    setShowAlert,
    setFormValid,
    setShowCancelEdit,
    setShowCancelBatch,
    setCardAreaRoute,
    updateAddUserState,
    setPanelShow,
    addPendingUser,
    updatePendingUser,
    setSelectedIndex,
    removePendingUser,
    setSelectAllLoading,
    updateAllPendingUsers,
    clearPendingUsers,
    resetUsersRolesToDefaults,
    updateErrorText,
    updateFormChangeCompany,
    clearFormChangeCompany,
    setInitialUserState,
    setConfirmationModalShow,
    setIsContinueConfirmationModal,
    setLastReloadDate,
    setIsInternalSingle,
    setIsAnimationLoading,
    setValidationError,
  },
  reducer: manageUsersReducer,
} = createSlice({
  name: 'manageUsers',
  initialState,
  reducers: {
    selectUser(state, { payload }: PayloadAction<SearchedUser>) {
      if (indexOfUserInSelectedUsers(payload, state.selectedUsers) < 0) {
        state.selectedUsers.push(payload);
      }
    },
    deselectUser(state, { payload }: PayloadAction<{ email?: string; phone?: string }>) {
      const indexLoc = indexOfUserInSelectedUsers(payload, state.selectedUsers);
      if (indexLoc > -1) {
        state.selectedUsers.splice(indexLoc, 1);
      }
    },
    addPendingUser(state, { payload }: PayloadAction<AddUser>) {
      if (
        state.addUserState.permissionSelected === PermissionSelection.SAME_PERMISSIONS &&
        state.cardAreaState.addedUsers.users[0]?.roles
      ) {
        state.cardAreaState.addedUsers.users.push({ ...payload, roles: state.cardAreaState.addedUsers.users[0].roles });
      } else {
        state.cardAreaState.addedUsers.users.push(payload);
      }
      state.cardAreaState.addedUsers.errors.push({});
    },
    updatePendingUser(state, { payload }: PayloadAction<{ changed: AddUser; cardNo: number }>) {
      state.cardAreaState.addedUsers.users[payload.cardNo] = {
        ...state.cardAreaState.addedUsers.users[payload.cardNo],
        ...payload.changed,
      };
      state.addUserState.permissionErrors = checkPermissions(state.cardAreaState.addedUsers.users);
    },
    updateAllPendingUsers(state, { payload }: PayloadAction<{ changed: Partial<SearchedUser> }>) {
      state.cardAreaState.addedUsers.users = state.cardAreaState.addedUsers.users.map((user) => ({
        ...user,
        ...payload.changed,
      }));
      state.addUserState.permissionErrors = checkPermissions(state.cardAreaState.addedUsers.users);
    },
    updateErrorText(state, { payload }: PayloadAction<{ changed: { [key: string]: string }; cardNo: number }>) {
      state.cardAreaState.addedUsers.errors[payload.cardNo] =
        Object.keys(payload.changed).length === 0 ? { valid: 'true' } : payload.changed;
    },
    setSelectedIndex(state, { payload }: PayloadAction<number>) {
      state.cardAreaState.selectedIndex = payload;
    },
    removePendingUser(state, { payload }: PayloadAction<number>) {
      state.cardAreaState.addedUsers.users.splice(payload, 1);
      if (
        state.cardAreaState.addedUsers.users.length - 1 < state.cardAreaState.selectedIndex ||
        state.cardAreaState.selectedIndex === -1
      ) {
        state.cardAreaState.selectedIndex = 0;
      }
      state.cardAreaState.addedUsers.errors.splice(payload, 1);
      if (state.addUserState.permissionSelected) {
        state.addUserState.addUserRoute =
          state.cardAreaState.addedUsers.users.length === 0 ? AddUserRoute.NO_CARDS : state.addUserState.addUserRoute;
      }
      state.cardAreaState.addedUsers.validationErrors.splice(payload, 1);
    },
    clearPendingUsers(state) {
      state.cardAreaState.addedUsers.users = [];
      state.cardAreaState.addedUsers.errors = [];
      state.cardAreaState.addedUsers.validationErrors = [];
    },
    resetUsersRolesToDefaults(state, { payload }: PayloadAction<Role[]>) {
      state.cardAreaState.addedUsers.users = state.cardAreaState.addedUsers.users.map((user) => ({
        ...user,
        roles: payload,
      }));
    },
    openEditUsers(state, { payload }: PayloadAction<SearchedUser | undefined>) {
      if (!state.isAnimationLoading) {
        if (payload) {
          state.selectedUsers = [payload];
        }
        // Prevents opening and erroring of slide panel if no user is selected or refresh fails to get user after update
        if (state.selectedUsers.length > 0 || payload) {
          state.editUserRoute.panelRoute = SingleEditPanelRoute.EDIT_USER;
          state.showUserEditPanel = true;
        }
      }
    },
    setPanelRoute(
      state: ManageUsersState,
      {
        payload,
      }: PayloadAction<{
        route?: SingleEditPanelRoute | BatchEditPanelRoute | SharedEditPanelRoute;
        quickActionMessage?: string;
        closeDelay?: number;
        routeParams?: Record<string, unknown>;
      }>,
    ) {
      state.editUserRoute.panelRoute = payload.route;
      state.quickActionMessage = payload.quickActionMessage ?? '';
      state.editUserRoute.closeDelay = payload.closeDelay ?? 0;
      state.editUserRoute.routeParams = payload.routeParams;
    },
    setCardAreaRoute(state, { payload }: PayloadAction<CardAreaRoute>) {
      state.cardAreaState.cardAreaRoute = payload;
    },
    updateAddUserState(state, { payload }: PayloadAction<AddUserState & { quickActionMessage?: string }>) {
      state.addUserState = { ...state.addUserState, ...payload };
      state.quickActionMessage = payload.quickActionMessage ?? state.quickActionMessage;
    },
    setPanelShow(state, { payload }: PayloadAction<boolean>) {
      state.showUserEditPanel = payload;
    },
    setSelectedUsers(state, { payload }: PayloadAction<SearchedUser[]>) {
      state.selectedUsers = payload;
    },
    closePanel(state) {
      state.editUserRoute.closeDelay = 0;
      state.showUserEditPanel = false;
      state.formState.hasChanges = false;
      state.formState.initialUserState = {} as SearchedUser;
      state.editUserRoute.routeParams = { secondaryNavigationIndex: 0 };
    },
    updateFormChangeCompany(state, { payload }: PayloadAction<Partial<ChangeCompanyState>>) {
      state.formState.changeCompany = { ...state.formState.changeCompany, ...payload };
    },
    clearFormChangeCompany(state) {
      state.formState.changeCompany = {};
    },
    setInitialUserState(state, { payload }: PayloadAction<SearchedUser>) {
      state.formState.initialUserState = payload;
    },
    setFormHasChanges(state, { payload }: PayloadAction<boolean>) {
      state.formState.hasChanges = payload;
    },
    setFormValid(state, { payload }: PayloadAction<boolean>) {
      state.formState.valid = payload;
    },
    setShowAlert(state, { payload }: PayloadAction<boolean>) {
      state.formState.showAlert = payload;
    },
    setShowCancelEdit(state, { payload }: PayloadAction<boolean>) {
      state.showCancelEdit = payload;
    },
    setShowCancelBatch(state, { payload }: PayloadAction<boolean>) {
      state.showCancelBatch = payload;
    },
    setSelectAllLoading(state, { payload }: PayloadAction<boolean>) {
      state.isSelectAllLoading = payload;
    },
    setConfirmationModalShow(state, { payload }: PayloadAction<boolean>) {
      state.confirmationModalShow = payload;
    },
    setIsContinueConfirmationModal(state, { payload }: PayloadAction<boolean>) {
      state.isContinueConfirmationModal = payload;
    },
    setLastReloadDate(state, { payload }: PayloadAction<dayjs.Dayjs>) {
      if (state.selectedUsers.length === 1) {
        state.selectedUsers.at(0)!.lastInvitationsReloadDate = payload;
      }
    },
    setIsInternalSingle(state, { payload }: PayloadAction<boolean>) {
      state.isInternalSingle = payload;
    },
    setIsAnimationLoading(state, { payload }: PayloadAction<boolean>) {
      state.isAnimationLoading = payload;
    },
    setValidationError(
      state,
      { payload }: PayloadAction<{ index: number; value?: any; customErrors: Record<string, string> }>,
    ) {
      const combinedErrors = payload.value ? [...payload.value, payload.customErrors] : [payload.customErrors];
      state.cardAreaState.addedUsers.validationErrors[payload.index] = combinedErrors;
    },
  },
});

export default manageUsersReducer;

const indexOfUserInSelectedUsers = (
  user: { email?: string; phone?: string },
  selectedUsers: { email?: string; phone?: string }[],
) => selectedUsers.findIndex(({ email, phone }) => (email || user.email ? email === user.email : phone === user.phone));
