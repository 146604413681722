import React from 'react';
import { Icon } from 'shamrock-clover-ui';
import iconContactMail from '../../assets/iconContactMail.svg';
import styles from './techHelpStyles.scss';
import Analytics from '@Common/analytics';
import { useSelector } from 'react-redux';
import { isFeatureEnabled } from '../../../store/adminShell/selectors';
import { FeatureFlags } from '@Common/featuresFlagsTypes';

export const TechnicalHelpSection = () => {
  const useUpdatedSupportNumber = useSelector(isFeatureEnabled(FeatureFlags.USE_UPDATED_SUPPORT_NUMBER));
  const firebaseEvent = (linkType: string) => {
    Analytics.logFirebaseEvent('CONTACT_US', 'TECHNICAL_HELP', 'CLICK', { Description: `Support ${linkType}` });
  };
  return (
    <div className={styles.techHelpContainer}>
      <div className={styles.techHelpText}>
        Contact our support team if you have any questions or feedback regarding the RTS Pro application.
      </div>
      <div className={styles.lineBorder} />
      <div className={styles.techHelpContact}>
        <div className={styles.contactText}>RTS Pro Support Team</div>
        <div className={styles.contactPhone}>
          <Icon icon="phone" color="gray" size="18" />
          <a href="tel:8888747053" className={styles.phone} onClick={() => firebaseEvent('Phone')}>
            {useUpdatedSupportNumber ? '(855) 278-2552' : '(888) 874-7053'}
          </a>
        </div>
        <div className={styles.contactEmail}>
          <img alt="email-icon" src={iconContactMail} />
          <a href="mailto:support@rtspro.com" className={styles.email} onClick={() => firebaseEvent('Email')}>
            Message
          </a>
        </div>
      </div>
    </div>
  );
};
