import React, { FC } from 'react';
import cx from 'classnames';
import SkeletonComponent from 'react-loading-skeleton';
// @ts-ignore We need access to the class names programmatically after they get obfuscated by our loader
import skeletonCss from 'react-loading-skeleton/dist/skeleton.css';
import css from './Placeholder.scss';

interface PlaceholderProps {
  height?: string | number;
  width?: string | number;
  baseColor?: string;
  highlightColor?: string;
  circle?: boolean;
  className?: string;
}

export const Placeholder: FC<PlaceholderProps> = ({ className, ...props }) => (
  <SkeletonComponent
    containerClassName={cx(!props.width && css.placeholder)}
    className={cx(skeletonCss['react-loading-skeleton'], !props.width && css.placeholder, className)}
    {...props}
  />
);

export default Placeholder;
