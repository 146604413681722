import React from 'react';
import cx from 'classnames';
import { ServiceHeader } from './ServiceHeader';
import iconFuelPump from '../../assets/iconFuelPump.svg';
import iconProTransport from '../../assets/iconProTransport.svg';
import iconFactoring from '../../assets/iconFactoring.svg';
import { LearnMoreBtn } from '../LearnMoreBtn';
import styles from './repStyles.scss';
export const NoFeatures = () => {
  return (
    <div className={cx(styles.repCardsContainer, styles.flexColumn)}>
      <div className={styles.noFeaturesTitle}>You do not have additional features in RTS Pro.</div>
      <div className={styles.noFeatureSubtitle}>See how we can help you save money and grow your business!</div>
      <div className={styles.servicesContainer}>
        <div className={styles.serviceContainer}>
          <ServiceHeader icon={iconFuelPump} serviceName="Fuel" />
          <LearnMoreBtn target={'fuel'} />
        </div>
        <div className={styles.lineBorder} />
        <div className={styles.serviceContainer}>
          <ServiceHeader icon={iconFactoring} serviceName="Factoring" />
          <LearnMoreBtn target={'factoring'} />
        </div>
        <div className={styles.lineBorder} />
        <div className={styles.serviceContainer}>
          <ServiceHeader icon={iconProTransport} serviceName="Freight" />
          <LearnMoreBtn target={'freight'} />
        </div>
      </div>
    </div>
  );
};
