import { withAuthenticationRequired } from '@auth0/auth0-react';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import React, { FC, ReactElement } from 'react';
import { Spinner } from 'shamrock-clover-ui';
import styles from './protectedRoute.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const isAuthed = () => {
  const token = LocalAuth.getTokenValue();
  return !!token;
};

const backwardsCompatAuthentication = (Component: FC<any>) => {
  return function BackwardsCompatAuthentication(props: any) {
    const isOldAuth = isAuthed();
    const features = useSelector((state: RootState) => state.adminShell.userFeatures);
    const isAuth0Enabled = features.includes('43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd');
    return isOldAuth || !isAuth0Enabled ? (
      <Component {...props} />
    ) : (
      withAuthenticationRequired(Component, {
        onRedirecting: () => (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        ),
        loginOptions: {
          openUrl: (url) => {
            window.location.replace(url);
          },
        },
      })(props)
    );
  };
};

interface ProtectedComponentProps {
  children: ReactElement;
}
const ProtectedComponent: FC<ProtectedComponentProps> = ({ children }) => {
  return {
    ...children,
  };
};

export default backwardsCompatAuthentication(ProtectedComponent);
