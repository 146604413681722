import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Base64Png } from '../../CMS/common/components/EditTray/Content/Design/FileUpload/processFilesUtil';
import { MarketingContent } from '../../services/marketingContentService';
import { MarketingTypes, MarketingWidgets } from '../../constants';

export interface BaseWidget extends MarketingContent {
  id: string; // Required for DNDKit. Sets the sort order.
  sortIndex: string;
  name: string;
  active: boolean;
  createdOn?: string;
  requiredPermissions?: Array<string>;
  pcOnly: boolean;
  fuelCards?: Array<string>;
  bodyText?: string;
  image: Base64Png;
  description?: string;
  marketingType: MarketingWidgets;
  link: string;
}

export interface MarketingWidget extends BaseWidget {
  marketingType: MarketingTypes.MARKETING_WIDGET | MarketingTypes.TRIAL_MARKETING_WIDGET;
  openOnFirstView: boolean;
  linkText: string;
}

export interface HomescreenWidget extends BaseWidget {
  marketingType: MarketingTypes.HOMESCREEN_WIDGET | MarketingTypes.TRIAL_HOMESCREEN_WIDGET;
  dynamicComponent?: string;
  headerText?: string;
  addChevron?: boolean;
  addBody?: boolean;
  trialTag?: boolean;
}

interface OrderUpdatePayload {
  orderDoc: MarketingWidgets | null;
  newOrder: Array<string>;
  initialOrder: Array<string>;
}

interface SnackBarHandlingPayload {
  snackBarMessage: string;
  isSnackBarOpen: boolean;
  snackBarVariant?: 'error' | 'success' | 'info' | undefined;
}
interface WidgetState {
  trialHomescreenWidgets: Array<HomescreenWidget>;
  trialMarketingWidgets: Array<MarketingWidget>;
  marketingWidgets: Array<MarketingWidget>;
  homescreenWidgets: Array<HomescreenWidget>;
  trialHomescreenOrder: Array<string>;
  trialMarketingOrder: Array<string>;
  homescreenOrder: Array<string>;
  marketingOrder: Array<string>;
  orderUpdate: OrderUpdatePayload;
  snackBarHandling: SnackBarHandlingPayload;
  loadingWidgets: boolean;
  isContainerMuted: boolean;
}

export const snackBarInitialState = {
  isSnackBarOpen: false,
  snackBarMessage: '',
  snackBarVariant: undefined,
};

const initialState: WidgetState = {
  homescreenWidgets: [],
  marketingWidgets: [],
  trialHomescreenWidgets: [],
  trialMarketingWidgets: [],
  homescreenOrder: [],
  marketingOrder: [],
  trialHomescreenOrder: [],
  trialMarketingOrder: [],
  orderUpdate: { orderDoc: null, newOrder: [], initialOrder: [] },
  snackBarHandling: snackBarInitialState,
  loadingWidgets: false,
  isContainerMuted: false,
};

export const {
  actions: {
    setHomescreenWidgets,
    setHomescreenOrder,
    setTrialHomescreenOrder,
    setTrialHomescreenWidgets,
    setMarketingWidgets,
    setMarketingOrder,
    setTrialMarketingOrder,
    setTrialMarketingWidgets,
    setOrderUpdate,
    setSnackBarHandling,
    setLoadingWidgets,
    setIsContainerMuted,
  },
  reducer: widgetReducer,
} = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setHomescreenWidgets(state, { payload }: PayloadAction<Array<HomescreenWidget>>) {
      state.homescreenWidgets = payload;
    },
    setTrialHomescreenWidgets(state, { payload }: PayloadAction<Array<HomescreenWidget>>) {
      state.trialHomescreenWidgets = payload;
    },
    setMarketingWidgets(state, { payload }: PayloadAction<Array<MarketingWidget>>) {
      state.marketingWidgets = payload;
    },
    setTrialMarketingWidgets(state, { payload }: PayloadAction<Array<MarketingWidget>>) {
      state.trialMarketingWidgets = payload;
    },
    setHomescreenOrder(state, { payload }: PayloadAction<Array<string>>) {
      state.homescreenOrder = payload;
    },
    setTrialHomescreenOrder(state, { payload }: PayloadAction<Array<string>>) {
      state.trialHomescreenOrder = payload;
    },
    setMarketingOrder(state, { payload }: PayloadAction<Array<string>>) {
      state.marketingOrder = payload;
    },
    setTrialMarketingOrder(state, { payload }: PayloadAction<Array<string>>) {
      state.trialMarketingOrder = payload;
    },
    setOrderUpdate(state, { payload }: PayloadAction<OrderUpdatePayload>) {
      state.orderUpdate = payload;
    },
    setSnackBarHandling(state, { payload }: PayloadAction<SnackBarHandlingPayload>) {
      state.snackBarHandling = payload;
    },
    setLoadingWidgets(state, { payload }: PayloadAction<boolean>) {
      state.loadingWidgets = payload;
    },
    setIsContainerMuted(state, { payload }: PayloadAction<boolean>) {
      state.isContainerMuted = payload;
    },
  },
});

export default widgetReducer;
