import React, { FC } from 'react';
import styles from '../styles.scss';
import { Button } from 'shamrock-clover-ui';
import Analytics from '@Common/analytics';

interface LearnMoreBtnProps {
  target: string;
}

const firebaseParamSelector = (target: string) => {
  if (target === 'loads') return 'ProTransport';
  return target.charAt(0).toUpperCase() + target.slice(1);
};

export const LearnMoreBtn: FC<LearnMoreBtnProps> = ({ target = '' }) => {
  const { coreWebUrl } = config;
  const onClick = () => {
    Analytics.logFirebaseEvent('CONTACT_US', 'ACCOUNT_HELP', 'CLICK', {
      Description: firebaseParamSelector(target) + '-Learn More',
    });
    window.open(`${coreWebUrl}/${target}`, '_blank');
  };
  return (
    <Button
      buttonStyle="outlined"
      color="blue"
      width="116px"
      isRounded
      className={styles.btnLearnMore}
      onClick={onClick}
    >
      LEARN MORE
    </Button>
  );
};
