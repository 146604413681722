import React, { FC } from 'react';
import Analytics from '@Common/analytics';
import { useJoyrideContext } from '@Common/components/Joyride/context';
import { JOYRIDES } from '@Common/components/Joyride/joyrides';
import { useAppSelector } from '../../store';
import { MANAGE_USER_ROUTES } from '../../ManageUsers/constants';
import JoyrideIcon from './JoyrideIcon';
import styles from '../styles/AdditionalAction.scss';
import { useTranslation } from 'react-i18next';

const ManageUsersJoyrideButton = () => {
  const { permissions: userPermissions, email, company } = useAppSelector((state) => state.adminShell.user);
  const { setState: setJoyrideState } = useJoyrideContext();
  const { t } = useTranslation();

  const sendAnalyticEvent = (analyticsDescription: string) => {
    Analytics.sendEvent(Analytics.EVENTS.CLICKV2, Analytics.MODULES.ADMIN_MANAGE_USERS, Analytics.PAGES.COMPANY_USERS, {
      Description: analyticsDescription,
      dateTimestamp: new Date().toISOString(),
      userEmail: email,
      userCompany: company,
    });
  };

  const onSkip = () => sendAnalyticEvent('Skip Tour');
  const onClose = () => sendAnalyticEvent('Exit Tour');

  // We only want to show the joyride on the external flow
  if (userPermissions.includes('carrierpro.RepCompanyAdmin')) return null;

  const handleClick = () => {
    sendAnalyticEvent('Take Tour');
    setJoyrideState({ run: true, joyride: JOYRIDES.MANAGE_USERS_EXTERNAL, analytics: { onSkip, onClose } });
  };

  return (
    <button className={styles.manageUsersButton} onClick={handleClick}>
      {t('joyrides.manage_users_external.take_a_tour')}
      <JoyrideIcon className={styles.joyrideIcon} />
    </button>
  );
};

const specialCases: { [key: string]: FC } = {
  [MANAGE_USER_ROUTES.Dashboard]: ManageUsersJoyrideButton,
};

interface AdditionalActionProps {
  currentIndex: number;
  tabs: {
    title: string;
  }[];
}

export const AdditionalAction: FC<AdditionalActionProps> = ({ currentIndex, tabs }) => {
  if (currentIndex > -1) {
    const tabTitle = tabs[currentIndex].title.toLowerCase();

    if (Object.keys(specialCases).includes(tabTitle)) {
      const Component = specialCases[tabTitle];
      return <Component />;
    }
  }

  return null;
};
