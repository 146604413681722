// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnsavedChangesAlert__alertButtons___ceL0U {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.UnsavedChangesAlert__alertButtons___ceL0U button {
  text-transform: capitalize;
}

.UnsavedChangesAlert__alertContent___Ke2OL p {
  padding: 0;
  margin: 0;
  line-height: normal;
  white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/common/components/UnsavedChangesAlert/UnsavedChangesAlert.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,0BAAA;AAEJ;;AAGE;EACE,UAAA;EACA,SAAA;EACA,mBAAA;EACA,qBAAA;AAAJ","sourcesContent":[".alertButtons {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  button {\n    text-transform: capitalize;\n  }\n}\n\n.alertContent {\n  p {\n    padding: 0;\n    margin: 0;\n    line-height: normal;\n    white-space: pre-wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertButtons": `UnsavedChangesAlert__alertButtons___ceL0U`,
	"alertContent": `UnsavedChangesAlert__alertContent___Ke2OL`
};
export default ___CSS_LOADER_EXPORT___;
