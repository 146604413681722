import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import type { Role } from '../../constants';

export const rolesServiceApi = createApi({
  reducerPath: 'roleServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.rolesServiceUrl }),
  tagTypes: ['roles'],
  endpoints: (builder) => ({
    getRoles: builder.query<Role[], void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      transformResponse: (response: Role[]) => {
        return response.sort((a, b) => a.name.localeCompare(b.name));
      },
      providesTags: ['roles'],
    }),
    getRoleUserCount: builder.query<number, string>({
      query: (roleId) => ({
        url: `/${roleId}/count/users`,
        method: 'GET',
      }),
    }),
    createRole: builder.mutation<Role, Partial<Role>>({
      query: (role) => ({
        url: '',
        method: 'POST',
        data: role,
      }),
      invalidatesTags: ['roles'],
    }),
    updateRole: builder.mutation<Role, Partial<Role>>({
      query: (role) => ({
        url: `/${role._id}`,
        method: 'PATCH',
        data: role,
      }),
      invalidatesTags: ['roles'],
    }),
    deleteRole: builder.mutation<void, string>({
      query: (roleId) => ({
        url: `/${roleId}`,
        method: 'DELETE',
        data: { _id: roleId },
      }),
      invalidatesTags: ['roles'],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleUserCountQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = rolesServiceApi;
