const { coreWebUrl } = config;

export const checkPermissions = (permissions: string[], requiredPermissions: string[] | string) =>
  Array.isArray(requiredPermissions)
    ? requiredPermissions.find((permission) => permissions.includes(permission))
    : permissions.find((permission) => permission === requiredPermissions);

export const getIndex = (permissions: string[], indexMap: Record<string, string | string[]>): string =>
  Object.entries(indexMap).find(([_, value]) => checkPermissions(permissions, value))?.[0] ??
  (indexMap.default as string);

export const redirectToLogin = (finalDestination: string) =>
  window.location.replace(`${coreWebUrl}/auth?finaldestination=${encodeURIComponent(finalDestination)}`);

export const isMobileDevice = () => {
  let check = false;
  const regex = /Mobile|iP(hone|od|ad)|Android|IEMobile/i;
  (function (a) {
    if (regex.test(a)) check = true;
  })(navigator.userAgent);
  return check;
};

export const checkIsOoidaRegister = (href: string): boolean =>
  href.split('/').includes('register') && href.split('/').includes('ooida');

export const formatPhoneNumber = (phone?: string, noSpaces = false) => {
  if (phone) {
    const cleaned = ('' + phone).replace(/\D/g, '').replace(/^1/, '');
    const tenDigitNumber = cleaned.substring(0, 10);
    const areaCode = tenDigitNumber.substring(0, 3);
    const middle = tenDigitNumber.substring(3, 6);
    const last = tenDigitNumber.substring(6, 10);
    if (tenDigitNumber.length > 6) {
      return noSpaces ? `(${areaCode}) ${middle}-${last}` : `(${areaCode}) ${middle} - ${last}`;
    } else if (tenDigitNumber.length > 3) {
      return `(${areaCode}) ${middle}`;
    } else if (tenDigitNumber.length > 0) {
      return `(${areaCode}`;
    }
  } else {
    return '';
  }
};

export const cleanPfjInput = (num?: string) => num?.replace(/\D/g, '');

export function capitalizeString(str: string) {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }

  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
