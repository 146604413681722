import Service from '../service';
import { AxiosResponse } from 'axios';

export class FeatureService extends Service {
  constructor() {
    super(config.featureServiceUrl);
  }
  async getUserFeatures(email: string) {
    const response: AxiosResponse = await this.axios.get(
      `${config.featureServiceUrl}/active?email=${encodeURIComponent(email)}`,
    );
    if (!response || response.status !== 200) {
      throw new Error();
    }

    return response.data;
  }

  async getAllFeatures() {
    const response: AxiosResponse = await this.axios.get(`${config.featureServiceUrl}/active`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}

export default new FeatureService();
