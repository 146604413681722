import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CompositionExternalBody,
  CompanyCompositionResponseQuery,
  UsersCompositionResponseQuery,
  CompositionInternalBody,
} from '../companyService';
import { Dispatch } from 'redux';
import { gql } from 'graphql-request';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { RootState } from '../../store';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { handleErrors } from '../serviceRtkQuery';

interface CompositionRequest {
  search: string;
  companyId?: string;
}

export const companyCompositionQuery = createApi({
  reducerPath: 'companyServiceApi',
  baseQuery: graphqlRequestBaseQuery({
    url: config.usersServiceUrl,
    requestHeaders: {
      'gql-data-return': 'true',
    },
    prepareHeaders: async (headers, { getState }) => {
      const adminShellState = (getState() as RootState).adminShell;
      const getAccessToken = adminShellState?.getAccessToken;
      const oldToken = LocalAuth.getTokenValue();
      let token = oldToken;
      const isAuth0Enabled = adminShellState?.userFeatures?.includes(
        '43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd',
      );
      if (!token && getAccessToken && isAuth0Enabled) token = await getAccessToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
    customErrors: async (error) => {
      await handleErrors(error);
    },
  }),
  tagTypes: ['companyComposition', 'userComposition'],
  endpoints: (builder) => ({
    companyComposition: builder.query<CompositionExternalBody, string>({
      query: (companyId) => {
        return {
          document: gql`
            query CompanyComposition($companyId: String) {
              companyComposition(companyId: $companyId) {
                active
                inactive
                pendingInvites
                userCount
              }
            }
          `,
          variables: {
            companyId,
          },
        };
      },
      providesTags: ['companyComposition'],
      transformResponse: (res: CompanyCompositionResponseQuery) => res.companyComposition,
    }),
    usersComposition: builder.query<CompositionInternalBody, CompositionRequest>({
      query: ({ search, companyId }) => {
        return {
          document: gql`
            query User($search: String, $companyId: String) {
              usersComposition(search: $search, companyId: $companyId) {
                active
                inactive
                pendingInvites
                unmatched
                userCount
              }
            }
          `,
          variables: {
            search,
            companyId,
          },
        };
      },
      providesTags: ['userComposition'],
      transformResponse: (res: UsersCompositionResponseQuery) => res.usersComposition,
    }),
  }),
});

export async function invalidateCompanyTags(
  _: unknown,
  { dispatch, queryFulfilled }: { dispatch: Dispatch; queryFulfilled: Promise<unknown> },
  tags: ('userComposition' | 'companyComposition')[],
) {
  try {
    const res = await queryFulfilled;
    if (res) dispatch(companyCompositionQuery.util.invalidateTags(tags));
  } catch (e) {
    console.error(e);
  }
}
export const { useCompanyCompositionQuery, useUsersCompositionQuery } = companyCompositionQuery;
