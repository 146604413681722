import React from 'react';

const JoyrideIcon = ({ className }: { className: string }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="27" height="25" fill="none">
    <path d="M17.67 5.77a8.95 8.95 0 0 0-8.93 8.94 8.95 8.95 0 0 0 8.93 8.94 8.95 8.95 0 0 0 8.94-8.94 8.95 8.95 0 0 0-8.94-8.94Zm0 1.8a7.14 7.14 0 0 1 7.15 7.14 7.13 7.13 0 0 1-7.15 7.15 7.13 7.13 0 0 1-7.14-7.15 7.14 7.14 0 0 1 7.14-7.15Zm-.89 2.67v1.79h1.79v-1.79h-1.79Zm0 3.58v5.36h1.79v-5.36h-1.79ZM3.36 4.52h1.45v1.45H3.36zM6.01 4.52h1.45v1.45H6.01z" />
    <mask id="joyrideMask" width="24" height="18" x="-.61" y=".8" fill="#000" maskUnits="userSpaceOnUse">
      <path fill="#fff" d="M-.61.8h24v18h-24z" />
      <path
        fillRule="evenodd"
        d="M1.69 3.1h19.66v5.05c.46.26.9.57 1.3.92V1.8H.39v16.64h10.66c-.27-.41-.5-.85-.68-1.3H1.69V3.1Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fillRule="evenodd"
      d="M1.69 3.1h19.66v5.05c.46.26.9.57 1.3.92V1.8H.39v16.64h10.66c-.27-.41-.5-.85-.68-1.3H1.69V3.1Z"
      clipRule="evenodd"
    />
    <path
      d="M21.35 3.1h.3v-.3h-.3v.3Zm-19.66 0v-.3h-.3v.3h.3Zm19.66 5.05h-.3v.18l.15.08.15-.26Zm1.3.92-.2.23.5.44v-.67h-.3Zm0-7.27h.3v-.3h-.3v.3ZM.39 1.8v-.3h-.3v.3h.3Zm0 16.64h-.3v.3h.3v-.3Zm10.66 0v.3h.56l-.3-.47-.26.17Zm-.68-1.3.28-.11-.08-.2h-.2v.3Zm-8.68 0h-.3v.3h.3v-.3ZM21.35 2.8H1.69v.6h19.66v-.6Zm.3 5.35V3.1h-.6v5.05h.6Zm-.45.26c.45.26.87.55 1.25.89l.4-.45a7.95 7.95 0 0 0-1.36-.96l-.3.52Zm1.75.66V3.1h-.6v5.97h.6Zm0-5.97V1.8h-.6v1.3h.6Zm-.3-1.6h-1.3v.6h1.3v-.6Zm-1.3 0H1.69v.6h19.66v-.6Zm-19.66 0H.39v.6h1.3v-.6Zm-1.6.3v1.3h.6V1.8h-.6Zm0 1.3v14.04h.6V3.1h-.6Zm0 14.04v1.3h.6v-1.3h-.6Zm.3 1.6h1.3v-.6H.39v.6Zm1.3 0h9.36v-.6H1.69v.6Zm9.61-.47c-.26-.39-.48-.8-.65-1.24l-.56.22c.2.47.43.93.71 1.35l.5-.33Zm-9.61-.83h8.68v-.6H1.69v.6ZM1.39 3.1v14.04h.6V3.1h-.6Z"
      mask="url(#joyrideMask)"
    />
    <path d="M1.17 7.07h14.08l-.96 1H1.17v-1ZM8.66 4.52h1.45v1.45H8.66z" />
  </svg>
);

export default JoyrideIcon;
