export const updateUser = {
  query: `mutation UpdateUser($user: UserInput, $changed: ChangedUser) {
      updateUser(user: $user, changed: $changed) {
          _id,
          email,
          firstname,
          lastname,
          phone,
          company,
          productRequests,
          pfjMyRewardsNumber
      }
    }`,
};

export const carrierDirectorySearch = {
  query: `query Search($search: String, $status: String, $pagedSortRequest: PagedSortRequest, $companyId: String, $focusedEmail: String) {
    search(search: $search, status: $status, pagedSortRequest: $pagedSortRequest, companyId: $companyId, focusedEmail: $focusedEmail) {
      users {
        _id
        email
        name
        company
        phone
        status
        createdOn
        lastUsed
        company_id
        company_type
        pfjMyRewardsNumber
        supervisor_email
        user_type
        notes
        roles {
          _id
          name
        }
        company_flagged
        device_info {
          devices_by_language {
              rtspro_language
              device_count
          }
        }
        mcleod_customer_id
        userStatus {
          trialState
          trialDaysLeft
          isTrial
          isOnCompany
          isRegistered
        }
        trials {
          createdOn
          createdBy
          expiration
        }
        trialExtensionRequested
      }
      totalItems
    }
  }`,
};

export const manageUsersSearch = {
  query: `query Search($search: String, $status: String, $pagedSortRequest: PagedSortRequest, $companyId: String, $focusedEmail: String) {
    search(search: $search, status: $status, pagedSortRequest: $pagedSortRequest, companyId: $companyId, focusedEmail: $focusedEmail) {
      users {
        _id
        email
        name
        company
        phone
        status
        createdOn
        lastUsed
        company_id
        pfjMyRewardsNumber
        roles {
          _id
          name
        }
        userStatus {
          trialDaysLeft
        }
      }
      totalItems
    }
  }`,
};
