import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-request';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { type RootState } from '../../store';

export interface FinancialAccount {
  reference_id: string;
  toDelete?: boolean;
}

export interface FinancialAccountData {
  active_account?: string;
  accounts: FinancialAccount[];
  user_id: string;
}

export const financialUsersServiceRTKApi = createApi({
  reducerPath: 'financialUsersServiceApi',
  baseQuery: graphqlRequestBaseQuery({
    requestHeaders: {
      'gql-data-return': 'true',
    },
    prepareHeaders: async (headers, { getState }) => {
      const adminShellState = (getState() as RootState).adminShell;
      const getAccessToken = adminShellState?.getAccessToken;
      const oldToken = LocalAuth.getTokenValue();
      let token = oldToken;
      const isAuth0Enabled = adminShellState?.userFeatures?.includes(
        '43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd',
      );
      if (!token && getAccessToken && isAuth0Enabled) token = await getAccessToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
    url: config.usersServiceUrl,
  }),
  tagTypes: ['FINANCIAL_USERS', 'FINANCIAL_USER'],
  endpoints: (builder) => ({
    getFinancialUser: builder.query<FinancialAccountData, string>({
      query: (email) => {
        return {
          document: gql`
            query GetFinancialUser($email: String) {
              getFinancialUser(email: $email) {
                active_account
                user_id
                accounts {
                  reference_id
                }
              }
            }
          `,
          variables: { email },
        };
      },
      transformResponse(response: { getFinancialUser: FinancialAccountData }) {
        return response.getFinancialUser;
      },
      providesTags: (result, error, id) => [{ type: 'FINANCIAL_USER', id }],
    }),
    getFinancialUsersWithAccount: builder.query<string[], string>({
      query: (account) => {
        return {
          document: gql`
            query GetUsersWithAccount($account: String) {
              getUsersWithAccount(account: $account)
            }
          `,
          variables: { account },
        };
      },
      transformResponse(response: { getUsersWithAccount: string[] }) {
        return response.getUsersWithAccount.sort((a, b) => a.localeCompare(b));
      },
      providesTags: ['FINANCIAL_USERS'],
    }),
    removeAccountUser: builder.mutation<FinancialAccountData, { email: string; account: string }>({
      query: ({ email, account }) => {
        return {
          document: gql`
            mutation DeleteAccount($email: String, $account: String) {
              deleteAccount(email: $email, account: $account) {
                user_id
                active_account
                accounts {
                  reference_id
                }
                last_modified_by
              }
            }
          `,
          variables: {
            email,
            account,
          },
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'FINANCIAL_USER', id: arg.email }, 'FINANCIAL_USERS'],
    }),
    addAccountsToFinancialUser: builder.mutation<FinancialAccountData, { email: string; accounts: string[] }>({
      query: ({ email, accounts }) => {
        return {
          document: gql`
            mutation UpsertFinancialUser($email: String, $accounts: [String!]) {
              upsertFinancialUser(email: $email, accounts: $accounts) {
                user_id
                active_account
                accounts {
                  reference_id
                }
              }
            }
          `,
          variables: {
            email,
            accounts,
          },
        };
      },
      invalidatesTags: ['FINANCIAL_USERS', 'FINANCIAL_USER'],
    }),
  }),
});

export const {
  useGetFinancialUserQuery,
  useGetFinancialUsersWithAccountQuery,
  useLazyGetFinancialUserQuery,
  useAddAccountsToFinancialUserMutation,
  useRemoveAccountUserMutation,
} = financialUsersServiceRTKApi;
