import { redirectToLogin } from '../utils';
import { AxiosError, AxiosInstance } from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { AppRoutes } from '../constants';
import Store from '../store';

const { publicPath: publicPath } = config;

export function addInterceptors(axios: AxiosInstance) {
  axios.interceptors.request.use(
    async (config) => {
      const adminShellState = Store.getState().adminShell;
      const getAccessToken = adminShellState?.getAccessToken;
      const oldToken = LocalAuth.getTokenValue();
      let token = oldToken;
      const isAuth0Enabled = adminShellState?.userFeatures?.includes(
        '43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd',
      );
      if (!token && getAccessToken && isAuth0Enabled) token = await getAccessToken();
      if (token && config.headers) {
        (config.headers as { Authorization: string }).Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
  );
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        redirectToLogin(`${publicPath}/${AppRoutes.CMS}`);
      }
      return Promise.reject(error);
    },
  );
}
