import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import { userServiceRTKApi } from '../usersService/usersServiceQuery';
import { User } from '../usersService';
import { companyCompositionQuery } from '../usersService/companyCompositionQuery';

export interface InvitationRequest {
  company_id: string;
  users: Array<Partial<User>>;
}

type InvitationErrResponse = {
  error?: string;
};

type InvitationResponse = {
  failures: Partial<User>[];
};

export const invitationServiceApi = createApi({
  reducerPath: 'invitationServiceAPI',
  baseQuery: axiosBaseQuery({ baseUrl: config.invitationServiceUrl }),
  endpoints: (builder) => ({
    sendInvitations: builder.mutation<InvitationResponse, InvitationRequest>({
      query: (invitationRequest) => {
        return {
          url: '/',
          method: 'POST',
          data: invitationRequest,
        };
      },
      transformErrorResponse: (response: { data: InvitationErrResponse }) => response.data,
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await queryFulfilled;
        dispatch(userServiceRTKApi.util.invalidateTags(['Search']));
        dispatch(companyCompositionQuery.util.invalidateTags(['companyComposition']));
      },
    }),
  }),
});

export const { useSendInvitationsMutation } = invitationServiceApi;
