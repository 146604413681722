import React from 'react';
import styles from './style.scss';
import Placeholder from '../../../common/components/Placeholder';

const PlaceholderCard = () => {
  return (
    <div className={styles['container-item']}>
      <div className={styles['placeholder-row']}>
        <div className={styles['placeholder-info']}>
          <Placeholder width="72.5vw" />
        </div>
      </div>
      <div className={styles['placeholder-rep-button']}>
        <Placeholder width="6vw" />
      </div>
    </div>
  );
};

export default PlaceholderCard;
