import React, { useMemo } from 'react';
import { Rep, useGetRepsExternalUseQuery } from '../services/repRetrievalService';
import contactUsIllustration from './assets/contactUsIllustration.svg';
import { ContactHeader } from './components/ContactHeader';
import { DropdownContainer } from './components/DropdownContainer';
import { Icon } from 'shamrock-clover-ui';
import { FAQContainer } from './components/FAQ/FAQContainer';
import { ContactUsReps } from './components/Reps/ContactUsReps';
import { useAppSelector } from '../store';
import { TechnicalHelpSection } from './components/TechHelp';
import { ContactUsSkeleton } from './SkeletonLoading';
import { NoFeatures } from './components/Reps/NoFeatures';
import styles from './styles.scss';
import { useTranslation } from 'react-i18next';

export type RepData = Rep | undefined;
type RepDataMemoReturn = [RepData, RepData, RepData] | undefined;

export const ContactUs = () => {
  const userEmail = useAppSelector((state) => state.adminShell.userEmail);
  const authType = useAppSelector((state) => state.adminShell.authed);
  const isUserLogged = authType === 'user';
  const { data, isLoading } = useGetRepsExternalUseQuery(userEmail!, { skip: !isUserLogged });

  const repsData = useMemo<RepDataMemoReturn>((): RepDataMemoReturn => {
    if (data?.some((rep) => rep.name)) {
      const fuelData = data.find((rep) => rep.product === 'Carrier Services');
      const factoringData = data.find((rep) => rep.product === 'RTS Financial');
      const proTransportData = data.find((rep) => rep.product === 'ProTransport');
      return [fuelData, factoringData, proTransportData];
    }
  }, [data]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <ContactUsSkeleton />
      ) : (
        <>
          <div className={styles.bodyContainer}>
            <ContactHeader />
            {isUserLogged && (
              <DropdownContainer content={<FAQBody />}>
                <FAQContainer />
              </DropdownContainer>
            )}
            {isUserLogged && (
              <DropdownContainer content={'Get help with your accounts.'} initialOpen={!!repsData}>
                {repsData ? <ContactUsReps repsData={repsData} /> : <NoFeatures />}
              </DropdownContainer>
            )}
            <DropdownContainer content={'Get technical help.'} initialOpen>
              <TechnicalHelpSection />
            </DropdownContainer>
          </div>
          <div className={styles.contactUsImageContainer}>
            <img src={contactUsIllustration} />
          </div>
        </>
      )}
    </div>
  );
};

const FAQBody = () => {
  const { t } = useTranslation();
  return (
    <>
      <Icon icon="faq" size="24" color="white" className={styles.icon} /> {t('faq')}
    </>
  );
};
