import React from 'react';
import styles from '../styles.scss';

export const ContactHeader = () => {
  return (
    <div>
      <div className={styles.title}>We are here to help.</div>
      <div className={styles.subTitle}>Monday - Friday | 8:00 am - 5:00 pm CST</div>
    </div>
  );
};
