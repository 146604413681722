import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import type { Role, Permission } from '../../constants';

export interface AssignableRolesByCompanyResponse {
  owner: string;
  assignableRoles: Role[];
}
type AssignableRolesByEmailResponse = Omit<AssignableRolesByCompanyResponse, 'owner'>;

export const permissionsServiceApi = createApi({
  reducerPath: 'permissionsServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.permissionsServiceUrl }),
  tagTypes: ['permissions'],
  endpoints: (builder) => ({
    getAssignableRolesByCompany: builder.query<AssignableRolesByCompanyResponse, string>({
      query: (companyId) => {
        return {
          url: `/get-assignable-roles?companyId=${companyId}`,
          method: 'GET',
        };
      },
      transformErrorResponse: () => {
        return [];
      },
    }),
    getAssignableRolesByEmail: builder.query<Role[], string>({
      query: (email) => {
        return {
          url: `/get-assignable-roles?email=${email}`,
          method: 'GET',
        };
      },
      transformResponse: (response: AssignableRolesByEmailResponse) => response.assignableRoles,
      transformErrorResponse: () => {
        return [];
      },
    }),
    getPermissions: builder.query<Permission[], void>({
      query: () => ({
        url: '/',
        method: 'GET',
      }),
      providesTags: ['permissions'],
    }),
    putPermissions: builder.mutation<void, Permission>({
      query: (permission: Permission) => ({
        url: '/',
        method: 'PUT',
        data: permission,
      }),
      invalidatesTags: ['permissions'],
    }),
  }),
});

export const {
  useGetAssignableRolesByCompanyQuery,
  useLazyGetAssignableRolesByCompanyQuery,
  useGetAssignableRolesByEmailQuery,
  useLazyGetAssignableRolesByEmailQuery,
  useGetPermissionsQuery,
  usePutPermissionsMutation,
} = permissionsServiceApi;
