import React, {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface ScrollLockContext {
  scrollLocked: boolean;
  scrollbarWidth: number;
  enableScroll: () => void;
  disableScroll: () => void;
}

const defaultContext = {
  scrollLocked: false,
  scrollbarWidth: 0,
  enableScroll: () => undefined,
  disableScroll: () => undefined,
};

export const scrollLockContext = createContext<ScrollLockContext>(defaultContext);
export const useScrollLock = () => useContext(scrollLockContext);

export const ScrollLockProvider: FC<PropsWithChildren> = ({ children }) => {
  const [scrollLocked, setScrollLock] = useState(false);

  // Do this as an effect to prevent flashing. If these methods are called when
  // the state is updated, because the dom is being modified directly, it causes
  // a brief flash while the React components wait for the state to propagate.
  useEffect(() => (scrollLocked ? disableBodyScroll() : enableBodyScroll()), [scrollLocked]);

  const enableScroll = useCallback(() => setScrollLock(false), []);
  const disableScroll = useCallback(() => setScrollLock(true), []);

  const value = useMemo(
    () => ({
      scrollLocked,
      scrollbarWidth: scrollLocked ? getScrollbarWidth() : 0,
      enableScroll,
      disableScroll,
    }),
    [scrollLocked],
  );

  return (
    <scrollLockContext.Provider value={value}>
      <>{children}</>
    </scrollLockContext.Provider>
  );
};

/**
 * @description This component is for when a child component may or may not have scrollbars;
 * It prevents layout jumps for when the scroll bars appear and disapear
 */

interface LockScrollProps {
  className: string;
  when?: boolean;
}
export const LayoutSafeLockScroll: FC<PropsWithChildren & LockScrollProps> = ({ className, children, when }) => {
  const { scrollbarWidth, enableScroll, disableScroll } = useScrollLock();

  useEffect(() => {
    if (!when) enableScroll();
  }, [when]);

  useEffect(() => enableScroll, []);

  return (
    <div
      className={className}
      onMouseEnter={disableScroll}
      onMouseLeave={enableScroll}
      onFocus={disableScroll}
      onBlur={enableScroll}
      style={{ paddingRight: checkBodyHasScroll() ? scrollbarWidth : 0 }}
    >
      {children}
    </div>
  );
};

const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  document.body.appendChild(outer);
  const widthNoScroll = outer.offsetWidth;

  outer.style.overflow = 'scroll';
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);
  const widthWithScroll = inner.offsetWidth;

  outer.parentNode?.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};

export const disableBodyScroll = () => {
  const bodyHasScroll = checkBodyHasScroll();
  document.body.style.overflow = 'hidden';
  if (bodyHasScroll) {
    document.body.style.paddingRight = `${getScrollbarWidth()}px`;
  }
};

const checkBodyHasScroll = () => {
  const hasLocalScroll = checkHasScroll(document.body);
  const hasGlobalScroll = window.innerHeight < document.body.clientHeight;
  return hasGlobalScroll || hasLocalScroll;
};

export const enableBodyScroll = () => {
  document.body.style.overflow = '';
  document.body.style.paddingRight = '';
};

const checkHasScroll = (element: HTMLElement | Element) => {
  const hasHorizontalScrollbar = element.scrollWidth > element.clientWidth;
  const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
  return hasHorizontalScrollbar || hasVerticalScrollbar;
};
