import React, { FC, useRef, useState } from 'react';
import styles from './faqStyles.scss';
import iconPlus from '../../assets/iconPlus.svg';
import iconMinus from '../../assets/iconMinus.svg';
import cx from 'classnames';
import Analytics from '@Common/analytics';

interface FAQCardProps {
  qText: string;
  aText: string;
}

const firebaseParamSelector: Record<string, string> = {
  'How to reset your password': 'Reset password',
  'How to change email': 'Change email',
  'How to add a User to My Company': 'Add a user',
};

export const FAQCard: FC<FAQCardProps> = ({ qText, aText }) => {
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleOnClick = () => {
    !isVisible &&
      Analytics.logFirebaseEvent('CONTACT_US', 'FAQ', 'CLICK', { Description: firebaseParamSelector[qText] });
    if (contentRef.current) {
      setIsVisible((prevIsVisible) => !prevIsVisible);
      const contentHeight = contentRef.current.scrollHeight;
      contentRef.current.style.maxHeight = isVisible ? '0' : `${contentHeight}px`;
    }
  };

  return (
    <div>
      <button className={cx(styles.expandableContainer, isVisible && styles.open)} onClick={handleOnClick}>
        <div className={styles.contentContainer}>
          <div className={styles.questionContainer}>
            <div className={styles.contentText}>{qText}</div>
          </div>

          <div className={cx(styles.contentAnswerText, isVisible && styles.expanded)} ref={contentRef}>
            {aText}
          </div>
        </div>
        <div className={styles.contentIcon}>
          <div>
            <img src={isVisible ? iconMinus : iconPlus} />
          </div>
        </div>
      </button>
    </div>
  );
};
