import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Step } from 'react-joyride';
import type { SeenJoyrides } from '../../../services/usersService';
import styles from './styles.scss';

interface ExtendedStep extends Step {
  roundedCorners?: boolean;
}

export enum JOYRIDES {
  MANAGE_USERS_EXTERNAL,
}

export const joyrideFieldMap: { [key in JOYRIDES]: keyof SeenJoyrides } = {
  [JOYRIDES.MANAGE_USERS_EXTERNAL]: 'adminManageUsersExternal',
};

export const useJoyrideSteps = () => {
  const { t, i18n } = useTranslation();
  const joyrideSteps: { [key in JOYRIDES]: ExtendedStep[] } = useMemo(() => {
    const manageUsersExternalSteps: ExtendedStep[] = [
      {
        target: '[data-joyrideid="UsersNavLink"]',
        placement: 'bottom-start',
        content: (
          <div>
            <div className={styles.joyrideTitle}>{t('joyrides.manage_users_external.0.title')}</div>
            <div className={styles.joyrideBody}>{t('joyrides.manage_users_external.0.body')}</div>
          </div>
        ),
        disableBeacon: true,
        showSkipButton: true,
        spotlightPadding: 2,
        styles: {
          tooltipFooterSpacer: {
            flex: '1 1 0',
          },
        },
      },
      {
        target: '[data-joyrideid="ManageUsersTable"]',
        content: (
          <div>
            <div className={styles.joyrideTitle}>{t('joyrides.manage_users_external.1.title')}</div>
            <div className={styles.joyrideBody}>{t('joyrides.manage_users_external.1.body')}</div>
          </div>
        ),
        disableBeacon: true,
        showSkipButton: false,
      },
      {
        target: '[data-joyrideid="ManageUsersSearch"]',
        content: (
          <div>
            <div className={styles.joyrideTitle}>{t('joyrides.manage_users_external.2.title')}</div>
            <div className={styles.joyrideBody}>{t('joyrides.manage_users_external.2.body')}</div>
          </div>
        ),
        disableBeacon: true,
        showSkipButton: false,
        styles: {
          spotlight: { borderRadius: '9999px' },
        },
      },
      {
        target: '[data-joyrideid="ManageUsersNameButton"]',
        placement: 'right',
        content: (
          <div>
            <div className={styles.joyrideTitle}>{t('joyrides.manage_users_external.3.title')}</div>
            <div className={styles.joyrideBody}>{t('joyrides.manage_users_external.3.body')}</div>
          </div>
        ),
        disableBeacon: true,
        showSkipButton: false,
        spotlightPadding: 4,
      },
      {
        target: '[data-joyrideid="ManageUsersSelectAll"]',
        placement: 'right',
        content: (
          <div>
            <div className={styles.joyrideTitle}>{t('joyrides.manage_users_external.4.title')}</div>
            <div className={styles.joyrideBody}>{t('joyrides.manage_users_external.4.body')}</div>
          </div>
        ),
        disableBeacon: true,
        showSkipButton: false,
        styles: {
          tooltip: {
            width: 400,
          },
        },
      },
      {
        target: '[data-joyrideid="ManageUsersAddButton"]',
        placement: 'left',
        content: (
          <div>
            <div className={styles.joyrideTitle}>{t('joyrides.manage_users_external.5.title')}</div>
            <div className={styles.joyrideBody}>{t('joyrides.manage_users_external.5.body')}</div>
          </div>
        ),
        disableBeacon: true,
        showSkipButton: true,
        styles: {
          buttonNext: { backgroundColor: '#72BF44' },
        },
      },
    ];

    return {
      [JOYRIDES.MANAGE_USERS_EXTERNAL]: manageUsersExternalSteps,
    };
  }, [i18n.language]);

  return joyrideSteps;
};
