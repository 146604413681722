import React, { FC } from 'react';
import { Rep } from '../../../services/repRetrievalService';
import { ServiceHeader } from './ServiceHeader';
import { ServiceRepDetails } from './ServiceRepDetails';
import styles from './repStyles.scss';

interface RepCardProps {
  icon: string;
  serviceName: string;
  data: Rep;
}

export const RepCard: FC<RepCardProps> = ({ icon, serviceName, data }) => {
  return (
    <div className={styles.repCard}>
      <ServiceHeader icon={icon} serviceName={serviceName} />
      <ServiceRepDetails rep={data} serviceName={serviceName} />
    </div>
  );
};
