import React, { FC } from 'react';
import iconFuelPump from '../../assets/iconFuelPump.svg';
import iconProTransport from '../../assets/iconProTransport.svg';
import iconFactoring from '../../assets/iconFactoring.svg';
import { RepCardFilter } from './RepCardFilter';
import { RepData } from '../..';
import styles from './repStyles.scss';

interface ContactUsRepsProps {
  repsData: [RepData, RepData, RepData];
}

export const ContactUsReps: FC<ContactUsRepsProps> = ({ repsData }) => {
  const [fuelData, factoringData, proTransportData] = repsData;

  return (
    <div className={styles.repCardsContainer}>
      <RepCardFilter icon={iconFactoring} serviceName="Factoring" target="factoring" data={factoringData} />
      <div className={styles.lineBorder} />
      <RepCardFilter icon={iconFuelPump} serviceName="Fuel" target="fuel" data={fuelData} />
      <div className={styles.lineBorder} />
      <RepCardFilter icon={iconProTransport} serviceName="ProTransport" target="loads" data={proTransportData} />
    </div>
  );
};
