// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: proxima-nova, arial, sans-serif;
  text-decoration: none;
  box-sizing: border-box;
}

div, p, section, h1, h2, h3, h4, h5, h6 {
  color: #333;
}

body {
  background-color: #F2F2F2;
  height: 100%;
  min-height: 100%;
  padding: 0;
  overflow: overlay;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,4CAAA;EACA,qBAAA;EACA,sBAAA;AADF;;AAIA;EACE,WCTgB;ADQlB;;AAIA;EACE,yBAAA;EACA,YAAA;EACA,gBAAA;EACA,UAAA;EACA,iBAAA;AADF","sourcesContent":["@use './variables.scss';\n\n* {\n  font-family: proxima-nova, arial, sans-serif;\n  text-decoration: none;\n  box-sizing: border-box;\n}\n\ndiv, p, section, h1, h2, h3, h4, h5, h6 {\n  color: variables.$font-color-dark;\n}\n\nbody {\n  background-color: #F2F2F2;\n  height: 100%;\n  min-height: 100%;\n  padding: 0;\n  overflow: overlay;\n}\n","$font-color-dark: #333;\n$font-color-light: #666;\n$border-color-light: #CCC;\n$border-color-lighter: #EEE;\n$shamrock-blue: #0091EA;\n$shamrock-light-blue: #EAF7FF;\n$shamrock-dark-blue: #005C95;\n$shamrock-dark-orange: #CC5F00;\n$admin-shell-height: 146px;\n$contact-us-container-width: 747px;\n$secondary-nav-height: 40px;\n$white: #ffff;\n$gray-10: #F9F9F9;\n$error-red: #EF3824;\n\n@mixin text-standard {\n    font-family: proxima-nova, arial, sans-serif;\n    color: $font-color-dark;\n    text-decoration: none;\n}\n\n@mixin addHeaderMargin {\n    margin-top: $admin-shell-height;\n}\n\n@mixin tinySpinnerFix {\n    > div > div {\n        display: inline-flex;\n        justify-items: center;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
