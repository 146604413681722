import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type UserTypeFilter = 'Primary Only' | 'All';
export type CardStatus = 'Inactive' | 'Active' | 'All';
export type ProductsFilter = Array<string>;

export interface FilterState {
  userType: UserTypeFilter;
  cardStatus: CardStatus;
  productsFilter: ProductsFilter;
}

const initialState: FilterState = {
  userType: 'All',
  cardStatus: 'All',
  productsFilter: [],
};
export const {
  actions: { updateUserType, updateCardStatus, updateProductsFilter, setProductsAll },
  reducer: filterReducer,
} = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    updateUserType: (state, { payload }: PayloadAction<UserTypeFilter | undefined>) => {
      state.userType = payload ?? 'All';
    },
    updateCardStatus: (state, { payload }: PayloadAction<CardStatus | undefined>) => {
      state.cardStatus = payload ?? 'All';
    },
    updateProductsFilter: (state, { payload }: PayloadAction<[string, 'removed' | 'added']>) => {
      const [filter, action] = payload;
      if (action === 'removed') {
        state.productsFilter = state.productsFilter.filter((product) => filter !== product);
        return;
      }
      if (action === 'added') {
        state.productsFilter.push(filter);
        return;
      }
    },
    setProductsAll: (state) => {
      state.productsFilter = [];
    },
  },
});

export default filterReducer;
