import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';

interface ClientAccountGetResponse {
  client_key: number;
  client_number: string;
  email: string;
  mc_number: string;
  name: string;
}

export const financialServiceApi = createApi({
  reducerPath: 'financialServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.financialServiceUrl }),
  endpoints: (builder) => ({
    getAccountName: builder.query<ClientAccountGetResponse[], string>({
      query: (clientNumber) => ({
        method: 'GET',
        url: `client/account/${clientNumber}`,
      }),
    }),
  }),
});

export const { useLazyGetAccountNameQuery } = financialServiceApi;
