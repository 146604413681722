import React, { FC } from 'react';
import { Icon } from 'shamrock-clover-ui';
import iconContactMail from '../../assets/iconContactMail.svg';
import { Rep } from '../../../services/repRetrievalService';
import { formatPhoneNumber } from '../../../utils';
import styles from './repStyles.scss';
import Analytics from '@Common/analytics';

interface ServiceRepDetailsProps {
  rep: Rep;
  serviceName: string;
}

export const ServiceRepDetails: FC<ServiceRepDetailsProps> = ({ rep, serviceName }) => {
  const firebaseEvent = (linkType: string) => {
    Analytics.logFirebaseEvent('CONTACT_US', 'ACCOUNT_HELP', 'CLICK', {
      Description: `${serviceName} Rep ${linkType}`,
    });
  };
  return (
    <div>
      <div className={styles.repName}>Rep: {rep.name}</div>
      {rep?.phone?.length > 0 && (
        <div className={styles.repPhoneContainer}>
          <Icon icon="phone" color="gray" size="18" />
          <a
            href={`tel:${formatPhoneNumber(rep.phone)}`}
            onClick={() => firebaseEvent('Phone')}
            className={styles.repPhone}
          >
            {formatPhoneNumber(rep.phone)}
          </a>
        </div>
      )}
      {rep?.email?.length > 0 && (
        <a href={`mailto:${rep.email}`} className={styles.repEmailContainer} onClick={() => firebaseEvent('Email')}>
          <img alt="email-icon" src={iconContactMail} />
          <div className={styles.repEmail}>Message</div>
        </a>
      )}
    </div>
  );
};
