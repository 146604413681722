import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styles from './styles.scss';

class ModalBox {
  private modal?: RefObject<HTMLDivElement>;
  public show: boolean;
  public setShowOverlay: (arg: boolean) => void;
  constructor(show: boolean, setShow: ModalBox['setShowOverlay']) {
    this.show = show;
    this.setShowOverlay = setShow;
  }
  public setModal = (ref: RefObject<HTMLDivElement>) => {
    this.modal = ref;
  };
  public getModal = () => this.modal;

  public static get emptyBox() {
    return new ModalBox(false, () => undefined);
  }
}
export const overlayContext = createContext(ModalBox.emptyBox);

export const useShowOverlay = () => {
  const { setShowOverlay } = useContext(overlayContext);
  return setShowOverlay;
};

interface OverlayProps extends PropsWithChildren {
  color?: 'light' | 'dark';
}

export const ModalContent: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const modalBoxContext = useContext(overlayContext);
  const parent = modalBoxContext.getModal()?.current;

  if (!parent) {
    return <></>;
  }
  return createPortal(children, parent);
};

export const ModalProvider: FunctionComponent<OverlayProps> = ({ children }) => {
  const { Provider } = overlayContext;
  const [show, setShow] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const overlay = useMemo(() => new ModalBox(show, setShow), []);
  useEffect(() => {
    overlay.setModal(modalRef);
  }, [modalRef]);
  return (
    <>
      <Provider value={overlay}>
        {/* Overlay */}
        <div className={show ? `${styles.overlay} ${styles.open}` : styles.overlay} />
        {/* Modal */}
        <div ref={modalRef} />
        {/* Everything Else */}
        <div className={show ? styles.overlayWrapper : undefined}>{children}</div>
      </Provider>
    </>
  );
};
