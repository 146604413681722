import React from 'react';
import css from './styles/Header.scss';
import { SecondaryNavigation } from 'shamrock-clover-ui';
import PrimaryNavigation from './components/PrimaryNavigation';
import { AdditionalAction } from './components/AdditionalAction';
import { useSubNavigation } from '../store/adminShell/selectors';
import { useTranslation } from 'react-i18next';

export const AdminShell = () => {
  const [tabs, currentIndex] = useSubNavigation();
  const { t } = useTranslation();
  return (
    <header className={css.headerContainer}>
      <div className={css.topHeader}>
        <div className={css.topHeaderText}>{t('manage_users.manage_account').toLocaleUpperCase()}</div>
      </div>
      <div className={css.header}>
        <PrimaryNavigation />
      </div>
      {tabs.length > 0 && currentIndex > -1 && (
        <div className={css.secondaryNavigationWrapper}>
          <div className={css.secondaryNavigationContainer}>
            <SecondaryNavigation tabs={tabs} startingIndex={currentIndex} />
          </div>
          <AdditionalAction currentIndex={currentIndex} tabs={tabs} />
        </div>
      )}
    </header>
  );
};
