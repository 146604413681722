import { useEffect, useState } from 'react';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { CompanyService } from '../../services/companyService';
import featureService from '../../services/featureService';
import UsersService, { User } from '../../services/usersService';
import { useAppDispatch } from '../../store';
import {
  setUserToken,
  setUserEmail,
  setUserCompanyData,
  setUserFeatures,
  setUser,
  setAuthed,
  setGetAccessToken,
} from '../../store/adminShell';
import { checkIsOoidaRegister, redirectToLogin } from '..';
import { AppState, useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

export const useLoadData = (oldAuth: boolean) => {
  const dispatch = useAppDispatch();
  const getUserActiveFeatures = async (email: string) =>
    dispatch(setUserFeatures(await featureService.getUserFeatures(email)));
  const {
    getAccessTokenSilently,
    isAuthenticated,
    getIdTokenClaims,
    isLoading: isLoadingAuth0,
    loginWithRedirect,
  } = useAuth0();
  const { getAccessToken, userFeatures, authed } = useSelector((state: AppState) => state.adminShell);
  const [isLoading, setIsLoading] = useState(true);
  const isAuth0Enabled = userFeatures?.includes('43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd');
  const [authFailed, setAuthFailed] = useState(false);
  const [openOoidaForm, setOpenOoidaForm] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setGetAccessToken(getAccessTokenSilently));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const handleLoadData = async () => {
      if (checkIsOoidaRegister(window.location.href)) {
        setOpenOoidaForm(true);
      } else {
        try {
          const userSession = {
            email: LocalAuth.getEmail(),
            token: LocalAuth.getTokenValue(),
          };
          if (userSession?.token) dispatch(setUserToken(userSession.token));
          if (userSession?.email) {
            dispatch(setUserEmail(userSession.email));
            if (userSession.token !== 'trialToken') {
              await fetchUserInformation(userSession.email, userSession.token);
            } else {
              await getUserActiveFeatures(userSession.email);
              dispatch(setAuthed('trial'));
            }
          } else {
            throw new Error('User session has no email');
          }

          // Remove token and email url params after committing to state
          const url = new URL(window.location.href);
          url.searchParams.delete('token');
          url.searchParams.delete('email');
          window.history.replaceState(null, '', url);
          setIsLoading(false);
        } catch (error) {
          //Nothing in local storage
          if (!isAuth0Enabled) {
            //Auth0 is not enabled
            redirectToLogin(window.location.href);
            return;
          }

          if (!isAuthenticated && !isLoadingAuth0) {
            // Auth0 is enabled and user is not authenticated and its no longer loading
            loginWithRedirect();
            return;
          }

          if (isAuthenticated) {
            // Auth0 is enabled and user is authenticated
            const claims = await getIdTokenClaims();
            const email = claims?.upn || claims?.email;
            if (email) {
              dispatch(setUserEmail(email));
              await fetchUserInformation(email);
              setIsLoading(false);
            }
          }
        }
      }
    };
    const getUserCompanyInfo = async (user: User) => {
      try {
        const companyService = new CompanyService();
        const [firstCompany] = await companyService.getCompanyByEmail(user.email);
        dispatch(setUserCompanyData(firstCompany));
      } catch (err) {
        console.log('Failed to get users company information.', err);
      }
    };

    const getUserInfo = async (user: User) => {
      try {
        const userPermissions = user.permissions.map((permission) => permission.name);
        dispatch(setUser({ ...user, permissions: userPermissions }));
        await getUserActiveFeatures(user.email);
        dispatch(setAuthed('user'));
      } catch (error) {
        setAuthFailed(true);
      }
    };

    const fetchUserInformation = async (email: string, token?: string) => {
      try {
        const usersService = new UsersService();
        const { user } = await usersService.getUserByEmail(email);
        getUserInfo(user);
        if (token !== 'trialToken') getUserCompanyInfo(user);
      } catch (err) {
        console.log('Failed to get user information.', err);
      }
    };
    if (userFeatures.length > 0 && (oldAuth || getAccessToken) && !authed) {
      handleLoadData();
    }
  }, [userFeatures, oldAuth, getAccessToken]);

  return {
    authFailed,
    openOoidaForm,
    isLoading,
  };
};
