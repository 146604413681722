import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useQueryParams = <T extends {}>() => {
  const [params, setParams] = useSearchParams();

  const updateQueryParams = useCallback(
    (paramsToAdd: T) => {
      setParams((prevParams) => {
        Object.entries(paramsToAdd).forEach(([key, value]) => {
          prevParams.set(key, value as string);
        });
        return prevParams;
      });
    },
    [setParams],
  );

  const clearQueryParams = () => {
    setParams();
  };

  const getQueryParam = (paramToGet: keyof T) => params.get(paramToGet as string);

  const removeQueryParam = useCallback(
    (paramToRemove: keyof T) => {
      setParams((prevParams) => {
        prevParams.delete(paramToRemove as string);
        return prevParams;
      });
    },
    [setParams],
  );

  const setQueryParams = (paramsToAdd: T) => {
    setParams(paramsToAdd);
  };

  return {
    clearQueryParams,
    getQueryParam,
    removeQueryParam,
    setQueryParams,
    updateQueryParams,
    queryParams: params,
  };
};

export interface ManageUsersSearchQuery {
  query?: string;
  status?: string;
  sort?: string;
  order?: string;
  perPage?: number;
  page?: number;
  crm?: string;
}

export const useSearchQueryParams = () => {
  const queryParamMethods = useQueryParams<ManageUsersSearchQuery>();

  const clearQueryParams = () => {
    const crm = queryParamMethods.getQueryParam('crm');
    queryParamMethods.setQueryParams(crm ? { crm } : {});
  };

  return { ...queryParamMethods, clearQueryParams };
};
