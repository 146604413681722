import React, {
  type FC,
  useState,
  useEffect,
  PropsWithChildren,
  useContext,
  useMemo,
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  createRef,
  MutableRefObject,
} from 'react';
import { Dialog } from 'shamrock-clover-ui';
import { useDispatch } from 'react-redux';
import { setFormHasChanges } from '../../../store/adminShell';
import styles from './UnsavedChangesAlert.scss';
import AlertButtons from './AlertButtons';
import { useAppSelector } from '../../../store';
import { useTranslation } from 'react-i18next';
import { useThrottle } from '@Common/utilCustomHooks';

type OptionsModal = {
  keepOpen?: boolean;
  onDiscardChanges?: () => void;
  onContinueEditing?: () => void;
  title?: string;
  body?: string;
  discardText?: string;
  continueText?: string;
};

export type ModalHandlePreventActionOnSelectionContext = {
  discarded: boolean;
  setDiscarded: Dispatch<SetStateAction<boolean>>;
  continued: boolean;
  setContinued: Dispatch<SetStateAction<boolean>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  body: string;
  setBody: Dispatch<SetStateAction<string>>;
  discardText: string | undefined;
  setDiscardText: Dispatch<SetStateAction<string | undefined>>;
  continueText: string | undefined;
  setContinueText: Dispatch<SetStateAction<string | undefined>>;
  showUnsavedChangesAlert: boolean;
  setShowUnsavedChangesAlert: Dispatch<SetStateAction<boolean>>;
  onDiscardRef: MutableRefObject<(() => void) | null>;
  onContinueRef: MutableRefObject<(() => void) | null>;
};

const onDiscardRefSingleton = createRef<() => void>();
const onContinueRefSingleton = createRef<() => void>();

export const unsavedChangesContext = createContext({
  continued: false,
  setContinued: () => {},
  discarded: false,
  setDiscarded: () => {},
  title: 'There are unsaved changes.',
  setTitle: () => {},
  body: 'Are you sure you want to leave this page? Changes will not be saved.',
  setBody: () => {},
  discardText: undefined,
  setDiscardText: () => {},
  continueText: undefined,
  setContinueText: () => {},
  showUnsavedChangesAlert: false,
  setShowUnsavedChangesAlert: () => {},
  onDiscardRef: onDiscardRefSingleton,
  onContinueRef: onContinueRefSingleton,
} as ModalHandlePreventActionOnSelectionContext);

const useUnsavedChanges = () => useContext(unsavedChangesContext);

export const UnsavedChangesAlertModal = () => {
  const { t } = useTranslation();
  const {
    setContinued,
    setDiscarded,
    title,
    setTitle,
    setBody,
    discardText,
    setDiscardText,
    continueText,
    setContinueText,
    showUnsavedChangesAlert,
    setShowUnsavedChangesAlert,
  } = useUnsavedChanges();

  useEffect(() => {
    if (!showUnsavedChangesAlert) {
      setTitle(t('unsaved_changes'));
      setBody(t('unsaved_changes_confirmation'));
      setDiscardText(undefined);
      setContinueText(undefined);
    }
  }, [showUnsavedChangesAlert]);

  const onDiscard = () => {
    setDiscarded(true);
    setShowUnsavedChangesAlert(false);
  };

  const onContinueEditing = () => {
    setShowUnsavedChangesAlert(false);
    setContinued(true);
  };
  return (
    <Dialog
      title={title}
      content={<AlertContent />}
      open={showUnsavedChangesAlert}
      actions={
        <AlertButtons
          discardText={discardText}
          continueText={continueText}
          onDiscard={onDiscard}
          onContinue={onContinueEditing}
        />
      }
    />
  );
};

export const UnsavedChangesAlertProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [discarded, setDiscarded] = useState<boolean>(false);
  const [continued, setContinued] = useState<boolean>(false);
  const [title, setTitle] = useState(t('unsaved_changes'));
  const [body, setBody] = useState(t('unsaved_changes_confirmation'));
  const [discardText, setDiscardText] = useState<string>();
  const [continueText, setContinueText] = useState<string>();
  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      discarded,
      setDiscarded,
      continued,
      setContinued,
      title,
      setTitle,
      body,
      setBody,
      discardText,
      setDiscardText,
      continueText,
      setContinueText,
      showUnsavedChangesAlert,
      setShowUnsavedChangesAlert,
      onDiscardRef: onDiscardRefSingleton,
      onContinueRef: onContinueRefSingleton,
    }),
    [
      discarded,
      setDiscarded,
      continued,
      setContinued,
      title,
      setTitle,
      body,
      setBody,
      discardText,
      setDiscardText,
      continueText,
      setContinueText,
      showUnsavedChangesAlert,
      setShowUnsavedChangesAlert,
      onDiscardRefSingleton,
      onContinueRefSingleton,
    ],
  );

  // Inner Fragmant required to apease react Types
  return (
    <unsavedChangesContext.Provider value={contextValue}>
      <>
        {children}
        <UnsavedChangesAlertModal />
      </>
    </unsavedChangesContext.Provider>
  );
};

const AlertContent = () => {
  const { body } = useUnsavedChanges();
  return (
    <div className={styles.alertContent}>
      <p>{body}</p>
    </div>
  );
};

export const useHasChangedAlertBox = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formHasChanges = useAppSelector((state) => state.adminShell.formHasChanges);
  const {
    continued,
    setContinued,
    discarded,
    setDiscarded,
    setTitle,
    setBody,
    setDiscardText,
    setContinueText,
    setShowUnsavedChangesAlert,
    onDiscardRef,
    onContinueRef,
  } = useUnsavedChanges();

  const throttle = useThrottle();

  useEffect(() => {
    if (discarded && onDiscardRef?.current) {
      onDiscardRef.current();
      setShowUnsavedChangesAlert(false);
      setDiscarded(false);
    }
  }, [discarded]);

  useEffect(() => {
    if (continued && onContinueRef?.current) {
      onContinueRef.current();
      setContinued(false);
    } else if (continued) {
      setContinued(false);
      setShowUnsavedChangesAlert(false);
    }
  }, [continued]);

  const hasChangesModal = useCallback(
    ({ onDiscardChanges, onContinueEditing, keepOpen, body, title, continueText, discardText }: OptionsModal = {}) => {
      if (title) setTitle(title);
      if (body) setBody(body);
      if (continueText) setContinueText(continueText);
      if (discardText) setDiscardText(discardText);

      if (formHasChanges) {
        onDiscardRef.current = () => {
          throttle(() => {
            !keepOpen && setShowUnsavedChangesAlert(false);
            onDiscardChanges && onDiscardChanges();
            setBody(t('unsaved_changes'));
            setTitle(t('unsaved_changes_confirmation'));
            setDiscardText(undefined);
            setContinueText(undefined);
            dispatch(setFormHasChanges(false));
          }, 500);
        };
        onContinueRef.current = () => {
          throttle(() => {
            onContinueEditing && onContinueEditing();
          }, 500);
        };
        setShowUnsavedChangesAlert(true);
        return;
      }
      onDiscardChanges && onDiscardChanges();
    },
    [formHasChanges],
  );

  return hasChangesModal;
};
