import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { JSONSchema7 } from 'json-schema';
import { ImageResult } from '../../CMS/common/components/EditTray/Content/Design/FileUpload/processFilesUtil';

export interface Deeplinks {
  [key: string]: string;
}

export interface Audience {
  cms_display_name: string;
  name: string;
}

/*
Mock json schema on state
Pull data from form on submit (or change - Look into partial validation)
Run Data from form against schema
Post validation errors...somewhere.
*/

export type Schemas = [string, JSONSchema7];
interface EditTrayState {
  showEditTray: boolean;
  selectedWidget?: string;
  deeplinks?: Deeplinks;
  audiences?: Audience[];
  fuelCards?: string[];
  uploadedImageState?: ImageResult;
  validationSchemas: Array<Schemas>;
  validationState: {
    hasValidated: boolean;
    hasValidationErrors: boolean;
    hasCardChanged: boolean;
    hasRefresh: boolean;
  };
  showCancelPopup: boolean;
  showDeletePopup: boolean;
  showSpinnerPopup: boolean;
  trayFilled: boolean;
}

const initialState: EditTrayState = {
  showEditTray: false,
  selectedWidget: undefined,
  deeplinks: undefined,
  audiences: undefined,
  fuelCards: undefined,
  validationSchemas: [],
  validationState: {
    hasValidated: false,
    hasValidationErrors: false,
    hasCardChanged: false,
    hasRefresh: false,
  },
  showCancelPopup: false,
  showDeletePopup: false,
  showSpinnerPopup: false,
  trayFilled: false,
};

export const {
  actions: {
    showEditTray,
    selectWidget,
    setDeeplinks,
    setAudiences,
    setFuelCards,
    setUploadedImageState,
    setSchema,
    setHasValidationErrors,
    setHasCardChanged,
    setHasValidated,
    showCancelPopup,
    showDeletePopup,
    showSpinnerPopup,
    setTrayFilled,
    setHasRefresh,
  },
  reducer: editTrayReducer,
} = createSlice({
  name: 'editTray',
  initialState,
  reducers: {
    showEditTray(state, { payload }: PayloadAction<boolean>) {
      state.showEditTray = payload;
    },
    selectWidget(state, { payload }: PayloadAction<string | undefined>) {
      state.selectedWidget = payload;
    },
    setDeeplinks(state, { payload }: PayloadAction<Deeplinks>) {
      state.deeplinks = payload;
    },
    setAudiences(state, { payload }: PayloadAction<Audience[]>) {
      state.audiences = payload;
    },
    setFuelCards(state, { payload }: PayloadAction<string[]>) {
      state.fuelCards = payload;
    },
    setUploadedImageState(state, { payload }: PayloadAction<ImageResult | undefined>) {
      state.uploadedImageState = payload;
    },
    setSchema(state, { payload: [name, schema] }: PayloadAction<Schemas>) {
      state.validationSchemas.push([name, schema]);
    },
    setHasValidationErrors(state, { payload }: PayloadAction<boolean>) {
      state.validationState = {
        ...state.validationState,
        // hasValidated: true,
        hasValidationErrors: payload,
      };
    },
    setHasValidated(state) {
      state.validationState = {
        ...state.validationState,
        hasValidated: true,
      };
    },
    setHasRefresh(state, { payload }) {
      state.validationState = {
        ...state.validationState,
        hasRefresh: payload,
      };
    },
    setHasCardChanged(state, { payload }) {
      state.validationState = {
        ...state.validationState,
        hasCardChanged: payload,
      };
    },
    showCancelPopup(state, { payload }: PayloadAction<boolean>) {
      state.showCancelPopup = payload;
    },
    showDeletePopup(state, { payload }: PayloadAction<boolean>) {
      state.showDeletePopup = payload;
    },
    showSpinnerPopup(state, { payload }: PayloadAction<boolean>) {
      state.showSpinnerPopup = payload;
    },
    setTrayFilled(state, { payload }: PayloadAction<boolean>) {
      state.trayFilled = payload;
    },
  },
});

export default editTrayReducer;
