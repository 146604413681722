import { useCallback } from 'react';
import { routeBuilder, MANAGE_USERS_ROUTE } from '../constants';
import type { CompanyResponse, CompositionExternalBody } from '../services/companyService';
import { FilterType, UserStatus, type SearchedUser } from '../services/usersService/usersServiceQuery';
import { useTranslation } from 'react-i18next';

export enum MANAGE_USER_ROUTES {
  Dashboard = 'dashboard',
}

export const manageUserRoutes = routeBuilder<MANAGE_USER_ROUTES>(MANAGE_USERS_ROUTE);

export enum QuickActionState {
  CONFIRMATION,
  LOADING,
  INITIAL,
}

export enum ConfirmationScreenTitles {
  Sent = 'Invitation Sent',
  Canceled = 'Invitation Canceled',
  Resent = 'Invitation Resent',
  RequestChange = 'Request Sent',
}

export const USER_TYPES = [
  'Accountant',
  'Administrator',
  'Dispatcher',
  'Driver (1099)',
  'Company Driver',
  'Owner',
] as const;

export const useGetStatusLabel = () => {
  const { t, i18n } = useTranslation();

  const getStatusLabel = useCallback(
    (status: FilterType | UserStatus, plural?: boolean) => {
      switch (status) {
        case FilterType.Active:
        case UserStatus.Active:
          return plural ? t('manage_users.active_users') : t('manage_users.active_user');
        case FilterType.Inactive:
        case UserStatus.Inactive:
          return plural ? t('manage_users.inactive_users') : t('manage_users.inactive_user');
        case FilterType.Pending:
        case UserStatus.Pending:
          return plural ? t('manage_users.invites_pending') : 'Invite Pending';
        case FilterType.Unmatched:
        case UserStatus.Unmatched:
          return 'Unmatched User';
        case UserStatus.TrialUser:
          return 'Trial User';
        default:
          return 'unknown status';
      }
    },
    [i18n.language],
  );

  return getStatusLabel;
};
export const JOYRIDE_COMPANY: Partial<CompanyResponse> = {
  company_id: '1000000',
  company_name: 'RTSPro Tour Co',
};

export const JOYRIDE_COMPANY_COMPOSITION: CompositionExternalBody = {
  active: 100,
  inactive: 2,
  pendingInvites: 12,
  userCount: 114,
};

export const JOYRIDE_USERS: SearchedUser[] = [
  {
    email: 'alansmithee@rtsprotour.com',
    name: 'Alan Sample User',
    company: 'RTSPro Tour Co',
    phone: '9135550100',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'carlosperez@rtsprotour.com',
    name: 'Carlos Sample User',
    company: 'RTSPro Tour Co',
    phone: '8165550191',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'dominikillich@rtsprotour.com',
    name: 'Dominik Sample User',
    company: 'RTSPro Tour Co',
    phone: '5745550174',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'ekapoor@rtsprotour.com',
    name: 'Ekam Sample User',
    company: 'RTSPro Tour Co',
    phone: '9705550165',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'lori.nguyen@rtsprotour.com',
    name: 'Lori Sample User',
    company: 'RTSPro Tour Co',
    phone: '4695550188',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'mdaiz@rtsprotour.com',
    name: 'María Sample User',
    company: 'RTSPro Tour Co',
    phone: '4045550132',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'rajsignh@rtsprotour.com',
    name: 'Raj Singh Sample User',
    company: 'RTSPro Tour Co',
    phone: '6025550109',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'rgutierrrez@rtsprotour.com',
    name: 'Ricardo Sample User',
    company: 'RTSPro Tour Co',
    phone: '6155550161',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'tanderson@rtsprotour.com',
    name: 'Terrance Sample User',
    company: 'RTSPro Tour Co',
    phone: '9545550123',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
  {
    email: 'willdavis@rtsprotour.com',
    name: 'William Sample User',
    company: 'RTSPro Tour Co',
    phone: '7735550179',
    status: UserStatus.Active,
    createdOn: '1655382003923',
    lastUsed: '1698694670042',
    company_id: '1000000',
    roles: [
      {
        _id: '2',
        name: 'Load Management',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
      {
        _id: '3',
        name: 'ProTransport Web User',
        description: '',
        permissions: [],
        salesforce: { category: '' },
        permanent: true,
        default_invite_role: false,
      },
    ],
  },
];
