import React from 'react';
import faqFile from '../../assets/faq.json';
import styles from './faqStyles.scss';
import { FAQCard } from './FAQCard';

export const FAQContainer = () => {
  return (
    <div className={styles.faqContainer}>
      {faqFile.faqs.map((faq) => {
        return <FAQCard key={faq.id} qText={faq.question} aText={faq.answer} />;
      })}
    </div>
  );
};
