import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';

export interface EmailObject {
  subject: string;
  to: string;
  templateId: string;
  from?: string;
  dynamicTemplateData?: {
    companyName?: string;
    webLink?: string;
    requestChange?: string;
  };
}

export const emailServiceApi = createApi({
  reducerPath: 'emailServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.emailServiceUrl }),
  endpoints: (builder) => ({
    sendEmail: builder.mutation<string, EmailObject>({
      query: (emailObject) => {
        return {
          url: '/',
          method: 'POST',
          data: emailObject,
        };
      },
    }),
  }),
});

export const { useSendEmailMutation } = emailServiceApi;
