import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { CallBackProps, ACTIONS, LIFECYCLE, EVENTS } from 'react-joyride';
import { JoyrideAnalytics, useJoyrideContext } from './context';
import { joyrideFieldMap, useJoyrideSteps } from './joyrides';
import css from './styles.scss';
import UsersService from '../../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setUser } from '../../../store/adminShell';
import { useScrollLock } from '../../../ManageUsers/components/common/utils/ScrollLock';

const buttonStyle = {
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  outline: 'none',
  lineHeight: 1,
  padding: 8,
  fontFamily: "'Proxima Nova', 'Roboto', Sans-serif",
  fontSize: 14,
  fontStyle: 'normal',
};

const styles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    primaryColor: '#f04',
    textColor: '#333',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    beaconSize: 36,
    zIndex: 100,
  },
  tooltip: {
    color: '#000',
    fontFamily: "'Proxima Nova', 'Roboto', Sans-serif",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    width: '350px',
    padding: '32px 32px 24px',
  },
  tooltipContainer: {
    textAlign: 'left',
  },
  tooltipContent: {
    padding: '0',
  },
  tooltipFooterSpacer: {
    flex: 'unset',
  },
  spotlight: {
    fill: '#F9F8F8',
    borderRadius: '4px',
  },
  buttonNext: {
    ...buttonStyle,
    backgroundColor: '#0091EA',
    borderRadius: '2px',
    color: '#fff',
    flexShrink: 0,
    padding: '12px 16px',
  },
  buttonSkip: {
    ...buttonStyle,
    color: '#000',
    paddingLeft: '0',
  },
  buttonBack: {
    ...buttonStyle,
    color: '#0091EA',
    paddingLeft: '0',
    marginLeft: 'unset',
    marginRight: 'auto',
  },
};

export default function JoyrideWrapper() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enableScroll, disableScroll } = useScrollLock();
  const user = useAppSelector((state) => state.adminShell.user);
  const {
    state: { run, joyride, analytics },
    setState,
  } = useJoyrideContext();
  const [updateButtonStyles, setUpdateButtonStyles] = useState(false);

  const usersService = useMemo(() => new UsersService(), []);
  const joyrideSteps = useJoyrideSteps();
  const steps = useMemo(() => (joyride !== undefined ? joyrideSteps[joyride] : undefined), [joyride]);

  useEffect(() => {
    if (run) {
      disableScroll();
      return () => enableScroll();
    }
  }, [run]);

  useEffect(() => {
    // Some styles cannot be configured via props. Apply the desired styles manually
    const primaryButton = document.querySelector('[data-test-id=button-primary]');
    primaryButton?.firstElementChild?.classList.add(css.primaryText);
  }, [updateButtonStyles]);

  const updateUserJoyride = () => {
    const existingMeta = user.metaData;
    const existingJoyrides = existingMeta?.seenJoyrides;
    const joyrideKey = joyride !== undefined && joyrideFieldMap[joyride];

    // We only need to update the user's metadata if they haven't already seen the joyride
    if (joyrideKey && (!existingJoyrides || !existingJoyrides[joyrideKey])) {
      const metaData = { ...existingMeta, seenJoyrides: { ...existingJoyrides } };
      metaData.seenJoyrides[joyrideKey] = true;

      usersService.updateUser({ email: user.email, phone: user.phone }, { metaData });
      dispatch(setUser({ ...user, metaData }));
    }
  };

  const handleAnalytics = (analytics: JoyrideAnalytics, action: string) => {
    switch (action) {
      case ACTIONS.NEXT:
        analytics.onNext && analytics.onNext();
        break;
      case ACTIONS.SKIP:
        analytics.onSkip && analytics.onSkip();
        break;
      case ACTIONS.CLOSE:
        analytics.onClose && analytics.onClose();
        break;
      case ACTIONS.PREV:
        analytics.onBack && analytics.onBack();
        break;
      default:
        break;
    }
  };

  const handleCallback = (data: CallBackProps) => {
    const { action, lifecycle, type } = data;
    if (analytics && lifecycle === LIFECYCLE.COMPLETE) {
      handleAnalytics(analytics, action);
    }

    if (type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setState({ run: false, joyride: undefined, analytics: undefined });
      return;
    }

    setUpdateButtonStyles(!updateButtonStyles);

    if (lifecycle === LIFECYCLE.READY && action === ACTIONS.START) {
      updateUserJoyride();
    }
  };

  return (
    <Joyride
      callback={handleCallback}
      continuous
      run={run}
      steps={steps}
      showProgress={true}
      disableCloseOnEsc
      disableOverlayClose
      scrollOffset={350}
      //@ts-ignore Joyride style types aren't up to date
      styles={styles}
      locale={{
        back: t('back'),
        next: t('next'),
        skip: t('joyrides.manage_users_external.skip_tour'),
        last: t('done'),
      }}
    />
  );
}
