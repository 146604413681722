import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AppRoutes } from './constants';
import { useAppSelector } from './store';
import { getIndex, redirectToLogin } from './utils';
import { useLoadData } from './utils/hooks';
import { AdminShell } from './AdminShell';
import { CarrierDirectory } from './CarrierDirectory';
import { ModalProvider } from './common/Modal';
import { PermissionsCheck } from './common/core/Permissions';
import { JoyrideProvider } from '@Common/components/Joyride/context';
import { ScrollLockProvider } from './ManageUsers/components/common/utils/ScrollLock';
import Joyride from '@Common/components/Joyride/Joyride';
import { ContactUs } from './ContactUs';
import FirebaseAnalytics from '@shamrock-core/common/analytics/firebase-analytics';
// @ts-ignore Import is configured via Webpack externals config
import ShamrockCoreConfig from 'ShamrockCoreConfig';
import { UnsavedChangesAlertProvider } from '@Common/components/UnsavedChangesAlert/UnsavedChangesAlert';
import { useAuth0 } from '@auth0/auth0-react';
import ProtectedComponent from '@Common/core/ProtectedComponent';
import { Spinner } from 'shamrock-clover-ui';
import featureService from './services/featureService';
import styles from './App.scss';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { setUserFeatures } from './store/adminShell';
import { useDispatch } from 'react-redux';

const CMS = React.lazy(() => import('./CMS'));
const Tools = React.lazy(() => import('./Tools'));
const ManageUsers = React.lazy(() => import('./ManageUsers'));
const Authorization = React.lazy(() => import('./Authorization'));
const ExternalRegistration = React.lazy(() => import('./ExternalRegistration'));

FirebaseAnalytics.init(ShamrockCoreConfig.firebaseConfig);

const indexMap = {
  [AppRoutes.MANAGE_USERS]: ['carrierpro.PermissionsAdmin', 'carrierpro.ViewRoles'],
  [AppRoutes.CMS]: ['internal.CMS.MarketingUser', 'internal.CMS.HomescreenUser'],
  default: '/contact-us',
};

const App = () => {
  const { isAuthenticated, isLoading: authLoading, loginWithRedirect } = useAuth0();
  const isAuthed = useAppSelector((state) => state.adminShell.authed);
  const userPermissions = useAppSelector((state) => state.adminShell.user.permissions);
  const { userFeatures } = useAppSelector((state) => state.adminShell);
  const cpua3558 = userFeatures.includes('CPUA-3558-WEB');
  const cpua3642 = userFeatures.includes('CPUA-3642');
  const cpua3696 = userFeatures.includes('CPUA-3696');
  const cpua5178 = userFeatures.includes('CPUA-5178');
  const dispatch = useDispatch();
  const [oldAuth, setOldAuth] = useState(false);
  const [noSession, setNoSessionExists] = useState(false);
  const isAuth0Enabled = userFeatures?.includes('43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd');

  const { authFailed, openOoidaForm, isLoading } = useLoadData(oldAuth);

  useEffect(() => {
    const initializeApp = async () => {
      const features = await featureService.getAllFeatures();
      const isAuth0EnabledGlobally = features?.includes(
        '43e3edab1c8c8e0366d34b17feeb203427cb10ae23e0a592b6be61d0b4aa8afd',
      );
      dispatch(setUserFeatures(features));
      try {
        LocalAuth.checkSession();
        setOldAuth(true);
      } catch (e) {
        setNoSessionExists(true);
        if (!isAuth0EnabledGlobally) {
          redirectToLogin(window.location.href);
        }
      }
    };
    initializeApp();
  }, []);

  useEffect(() => {
    if (noSession && !isAuthenticated && !authLoading && isAuth0Enabled) {
      loginWithRedirect();
    }
  }, [noSession, isAuthenticated, authLoading, isAuth0Enabled]);

  if (openOoidaForm) {
    return (
      <>
        <BrowserRouter>
          <ModalProvider>
            <AdminShell />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path={'/register/*'} element={<ExternalRegistration />} />
              </Routes>
            </Suspense>
          </ModalProvider>
        </BrowserRouter>
      </>
    );
  }

  return (
    <>
      {isAuthed && (
        <BrowserRouter>
          <ScrollLockProvider>
            <UnsavedChangesAlertProvider>
              <ModalProvider>
                <JoyrideProvider>
                  <AdminShell />
                  <Joyride />
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <ProtectedComponent>
                            <div>Home of Admin Shell</div>
                          </ProtectedComponent>
                        }
                      />
                      <Route
                        path={`${AppRoutes.CMS}/*`}
                        element={
                          <ProtectedComponent>
                            <PermissionsCheck
                              permissions={userPermissions}
                              requiredPermissions={['internal.CMS.HomescreenUser', 'internal.CMS.MarketingUser']}
                              fallback={`/${AppRoutes.ADMIN_SHELL_HOME}`}
                            >
                              <CMS />
                            </PermissionsCheck>
                          </ProtectedComponent>
                        }
                      />
                      {cpua3696 ? (
                        <Route
                          path={`${AppRoutes.MANAGE_USERS}/*`}
                          element={
                            <ProtectedComponent>
                              <ManageUsers />
                            </ProtectedComponent>
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {cpua5178 ? (
                        <Route
                          path={`${AppRoutes.AUTHORIZATION}/*`}
                          element={
                            <ProtectedComponent>
                              <PermissionsCheck
                                permissions={userPermissions}
                                requiredPermissions={['carrierpro.PermissionsAdmin', 'carrierpro.ViewRoles']}
                                fallback={`/${AppRoutes.ADMIN_SHELL_HOME}`}
                              >
                                <Authorization />
                              </PermissionsCheck>
                            </ProtectedComponent>
                          }
                        />
                      ) : (
                        <></>
                      )}
                      <Route
                        index
                        element={
                          <ProtectedComponent>
                            <Navigate to={getIndex(userPermissions, indexMap)} />
                          </ProtectedComponent>
                        }
                      />
                      {/* TODO: Add Permissions */}
                      <Route
                        path={`${AppRoutes.RTSPRO_TOOLS}/*`}
                        element={
                          <ProtectedComponent>
                            <Tools />
                          </ProtectedComponent>
                        }
                      />
                      {cpua3642 ? (
                        <Route
                          path={`${AppRoutes.CARRIER_DIRECTORY}/*`}
                          element={
                            <ProtectedComponent>
                              <PermissionsCheck
                                permissions={userPermissions}
                                requiredPermissions={['carrierpro.RepCompanyAdmin']}
                                fallback={`/${AppRoutes.ADMIN_SHELL_HOME}`}
                              >
                                <CarrierDirectory />
                              </PermissionsCheck>
                            </ProtectedComponent>
                          }
                        />
                      ) : (
                        <></>
                      )}
                      <Route
                        index
                        element={
                          <ProtectedComponent>
                            <Navigate to={`./${AppRoutes.CMS}`} />
                          </ProtectedComponent>
                        }
                      />
                      {cpua3558 ? <Route path={`${AppRoutes.CONTACT_US}/*`} element={<ContactUs />} /> : <></>}
                    </Routes>
                  </Suspense>
                </JoyrideProvider>
              </ModalProvider>
            </UnsavedChangesAlertProvider>
          </ScrollLockProvider>
        </BrowserRouter>
      )}
      {authFailed && <p>User authorization cannot be confirmed at this time.</p>}
      {(isLoading || authLoading) && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default App;
