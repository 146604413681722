import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';

export type FuelCard = {
  marketing_name: string;
};

export type GetWinningFuelCardBody = {
  companyId: string;
  fuelServiceUrl: string;
};

export const fuelServiceApi = createApi({
  reducerPath: 'fuelServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: '' }),
  tagTypes: ['FuelProgram'],
  endpoints: (builder) => ({
    getWinningFuelCard: builder.query<FuelCard, GetWinningFuelCardBody>({
      query: (body) => ({
        url: `${body.fuelServiceUrl}/program-hierarchy/fuel-card/${body.companyId}`,
        method: 'GET',
      }),
      providesTags: ['FuelProgram'],
    }),
  }),
});

export const { useGetWinningFuelCardQuery } = fuelServiceApi;
