import React from 'react';
import Placeholder from '../common/components/Placeholder';
import styles from './styles.scss';

export const ContactUsSkeleton = () => {
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.skeletonBodyPane}>
        <div className={styles.skeletonHeader}>
          <Placeholder height="45px" width="439px" />
          <Placeholder height="31px" width="439px" />
        </div>
        <div className={styles.skeletonBody}>
          <SkeletonBodySection />
          <SkeletonBodySection />
          <SkeletonBodySection />
        </div>
      </div>
      <div className={styles.skeletonImagePane}>
        <Placeholder height="418px" width="517px" />
      </div>
    </div>
  );
};

const SkeletonBodySection = () => <Placeholder height="209px" width="747px" />;
